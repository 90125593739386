<template>
  <div class="frame">
    <div class="title">智慧就业创业综合服务平台<em>(最新政策)</em></div>
    <div class="time">
      <p id="time"></p>
      <span id="date"></span>
      <span id="week" style="margin-left: 0.3rem"></span>
    </div>
    <div class="header"></div>
    <div class="container">
      <div class="tab">
        <ul>
          <li v-for="(item, index) in tabList" :key="index" :class="{ 'active': navItem == index }" @click="change(index)">
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <div class="policyList" v-if="policyList.length > 0" ref="scrollWrapper"  @scroll="handleScroll">
        <ul>
          <li v-for="(item, index) in policyList" :key="index" :class="{ 'active': poliItem == index }" @click="onchange(index)">
            <i class="circle"></i>
            <span class="policyListTitle">{{ item.title }}</span>
            <span style="float: right;">{{ item.datatime_n }}</span>
          </li>
        </ul>
      </div>
      <div class="btn">
        <button class="home" @click="goHome">
          <i class="homeicon"></i>
          <span>首页</span>
        </button>
        <button class="menu" @click="getMenu">
          <i class="menuicon"></i>
          <span>菜单</span>
        </button>
        <button class="back" @click="goback">
          <i class="backicon"></i>
          <span>返回</span>
        </button>
        <div class="menuBox" v-if="isShow">
          <ul>
            <li v-for="(item,index) in menuList" :key="index" :class="'menu'+index" @click="goPath(item.path)">
              <i :class="'menuicon'+index"></i>
              <p>{{item.menuName}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../utils/request'
import * as uData from "../utils/data";
export default {
  name: "user",
  data() {
    return {
      policyList: [
        // {
        //   id: 1024,
        //   title: '七里河区创业担保贷款申请办理须知',
        //   datatime_n: '2024/5/16'
        // },
      ],
      poliItem: 0,
      menuList: [
        {
          menuName: "我要找工作",
          path:'/job'
        },
        {
          menuName: "我要招人才",
          path:'/personnel'
        },
        {
          menuName: "普工专区",
          path:'/generalWorkers'
        },
        {
          menuName: "我要找零活",
          path:'/gigwork'
        },
        {
          menuName: "业主招零工",
          path:'/oddjobs'
        },
        {
          menuName: "最新政策",
          path:'/policy'
        },
        {
          menuName: "办事流程",
          path:'/processHand'
        },
        {
          menuName: "我要学技能",
          path:'/learnSkills'
        },
      ],
      isShow: false,
      tabList: [
        // {
        //   name: '就业政策'
        // },
        // {
        //   name: '创业政策'
        // }
      ],
      navItem: 0,
      search: {
        nid: 0,
        page: 1
      },
      loading: false,
      isEnd: false
    }
  },
  mounted() {
    uData.runIntervalNowFormatDate();
    let query = this.$route.query;
    if(!Number.isNaN(parseInt(query.navItem))){
      this.navItem = parseInt(query.navItem);
    }
    this.getTabList();
  },
  beforeDestroy() {
    uData.clearIntervalNowFormatDate();
  },
  methods: {
    goPath(path){
      this.$router.push({path:path})
    },
    change(e){
      let self = this;
      this.navItem = e;
      let newnid = parseInt(this.tabList[self.navItem]["id"]);
      if (newnid != this.search.nid) {
        this.$router.replace("/policy?navItem=" + self.navItem);
        this.search.nid = newnid;
        this.search.page = 1;
        this.policyList = [];
        this.getList();
      }
    },
    onchange(val) {
      this.poliItem = val;
      let id = this.policyList[val] ? this.policyList[val]["id"] : 0;
      this.$router.push({ path: '/detail', query: {id: id, navItem: this.navItem}});
    },
    getMenu() {
      this.isShow = !this.isShow
    },
    goHome() {
      this.$router.push({ path: '/home' })
    },
    goback() {
      this.$router.push({ path: '/home' })
    },
    getTabList() {
      let self = this;
      http.get('?m=fuwuPolicy&c=getTabList').then(function (res) {
        if (!res.data.error) {
          self.tabList = res.data.list;
        }
      });
    },
    getList() {
      let self = this;
      if (self.loading) {
        return false;
      }
      let params = this.search;
      self.loading = true;
      http.get('?m=fuwuPolicy&c=index', {params: params}).then(function (res) {
        if (!res.data.error) {
          self.policyList.push(...res.data.list);
          self.isEnd = res.data.isEnd;
        }
      }).finally(function () {
        self.loading = false;
      });
    },
    handleScroll() {
      const container = this.$refs.scrollWrapper
      const scrollHeight = container.scrollHeight
      const scrollTop = container.scrollTop
      const clientHeight = container.clientHeight
      if (scrollTop + clientHeight >= scrollHeight && !this.loading) {
        this.loadMore()
      }
    },
    loadMore: function () {
      if (!this.isEnd) {
        this.search.page++;
        this.getList();
      }
    }
  },
  watch: {
    tabList: {
      handler(val) {
        if (val.length > 0) {
          this.search.nid = parseInt(val[this.navItem]["id"]);
          this.getList();
        }
      },
      immediate: true,
      deep: true,
    },
  }
};
</script>

<style lang="less" scoped>
.header {
  margin-top: 3.4rem;
  border-bottom: 1px solid #fff;
}

.container {
  padding: 0 2.4rem;
  .tab {
    margin-top: 0.5rem;
    li {
      display: inline-block;
      width: 5.5rem;
      height: 1.5rem;
      background: rgb(42, 63, 255);
      line-height: 1.5rem;
      text-align: center;
      border: 1px solid #fff;
      // border-radius: .125rem;
      span {
        font-size: 0.5rem;
        color: #fff;
        font-family: "SourceHanSansSC";
        font-weight: bold;
      }
    }
    .active {
      background-color: rgb(127, 248, 250);
      span {
        color:rgb(42, 63, 255);
        font-family: "SourceHanSansSC";
        font-weight: bold;
      }
    }
  }

  .policyList {
    margin-top: 0.9rem;
    height: 17rem;
    overflow-y: auto;
    padding: 0.25rem;

    li {
      width: 48%;
      display: inline-block;
      background: rgb(42, 63, 255);
      padding: 25px 0.4rem;
      margin-bottom: 0.6rem;
      margin-right: 4%;
      border-radius: 0.125rem;
      cursor: pointer;

      &:nth-child(2n) {
        margin-right: 0;
      }

      .circle {
        width: 0.25rem;
        height: 0.25rem;
        background: rgb(12, 255, 0);
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.4rem;
      }

      span {
        font-size: 0.4rem;
        color: #fff;
        display: inline-block;
        vertical-align: middle;
      }

      span.policyListTitle{
        width: calc(100% - 90px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .active {
      box-shadow: 0 0px 3px 4px rgba(255, 255, 255, 0.6);
    }
  }

  .btn {
    .home {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      left: 2.4rem;
      bottom: 1.3rem;
      cursor: pointer;

      .homeicon {
        background: url("../assets/images/jobImg/hombig.png") no-repeat;
        background-size: 100% 100%;
        width: 1.125rem;
        height: 0.975rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.875rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .menu {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      left: 7.5rem;
      bottom: 1.3rem;
      cursor: pointer;

      .menuicon {
        background: url("../assets/images/jobImg/menuicon.png") no-repeat;
        background-size: 100% 100%;
        width: 0.975rem;
        height: 0.975rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.575rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .back {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      right: 2.4rem;
      bottom: 1.3rem;
      cursor: pointer;

      .backicon {
        background: url("../assets/images/jobImg/back.png") no-repeat;
        background-size: 100% 100%;
        width: 1.125rem;
        height: 1.025rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.875rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .menuBox {
      position: absolute;
      left: 12rem;
      bottom: 1.3rem;
      background: url("../assets/images/jobImg/menubg.png") no-repeat;
      background-size: 100% 100%;
      width: 25.75rem;
      height: 3.425rem;
      padding: 0.55rem 1.05rem 0.55rem 1.5rem;
      li {
        width: 2.05rem;
        height: 2.05rem;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.95rem;
        text-align: center;
        padding-top: 0.35rem;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        p {
          font-size: 0.3rem;
          color: #fff;
          margin-top: 0.4rem;
        }
        i {
          display: block;
          margin: 0 auto;
        }
        .menuicon0 {
          background: url("../assets/images/menuImg/menuicon1.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.6rem;
        }
        .menuicon1 {
          background: url("../assets/images/menuImg/menuicon2.png") no-repeat;
          background-size: 100% 100%;
          width: 0.6rem;
          height: 0.625rem;
        }
        .menuicon2 {
          background: url("../assets/images/menuImg/menuicon3.png") no-repeat;
          background-size: 100% 100%;
          width: 0.65rem;
          height: 0.65rem;
        }
        .menuicon3 {
          background: url("../assets/images/menuImg/menuicon4.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.65rem;
        }
        .menuicon4 {
          background: url("../assets/images/menuImg/menuicon5.png") no-repeat;
          background-size: 100% 100%;
          width: 0.6rem;
          height: 0.675rem;
        }
        .menuicon5 {
          background: url("../assets/images/menuImg/menuicon6.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.675rem;
        }
        .menuicon6 {
          background: url("../assets/images/menuImg/menuicon7.png") no-repeat;
          background-size: 100% 100%;
          width: 0.8rem;
          height: 0.7rem;
        }
        .menuicon7 {
          background: url("../assets/images/menuImg/menuicon8.png") no-repeat;
          background-size: 100% 100%;
          width: 0.7rem;
          height: 0.7rem;
        }
      }
      .menu0 {
        background: url("../assets/images/menuImg/menu1.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu1 {
        background: url("../assets/images/menuImg/menu2.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu2 {
        background: url("../assets/images/menuImg/menu3.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu3 {
        background: url("../assets/images/menuImg/menu4.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu4 {
        background: url("../assets/images/menuImg/menu5.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu5 {
        background: url("../assets/images/menuImg/menu6.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu6 {
        background: url("../assets/images/menuImg/menu7.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu7 {
        background: url("../assets/images/menuImg/menu8.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>