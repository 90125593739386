<template>
  <div class="frame">
    <div class="title">智慧就业创业综合服务平台<em>(办事流程)</em></div>
    <div class="time">
      <p id="time"></p>
      <span id="date"></span>
      <span id="week" style="margin-left: 0.3rem"></span>
    </div>
    <div class="header"></div>
    <div class="container">
      <div class="processHandList" ref="scrollWrapper"  @scroll="handleScroll">
        <ul>
          <li v-for="(item, index) in processHandList" :key="index" @click="openModal(index)" :class="{ 'active': jobItem == index }">
            <p>{{ item.title }}</p>
          </li>
        </ul>
      </div>
      <div class="processHandCon">
        <div style="width: 100%; overflow: hidden;position: relative;display: flex;align-items: center;justify-content: center; ">
          <div class="control" v-html="info.content"></div>
        </div>
      </div>
      <div class="btn">
        <button class="home" @click="goHome">
          <i class="homeicon"></i>
          <span>首页</span>
        </button>
        <button class="menu" @click="getMenu">
          <i class="menuicon"></i>
          <span>菜单</span>
        </button>
        <button class="back" @click="goback">
          <i class="backicon"></i>
          <span>返回</span>
        </button>
        <div class="menuBox" v-if="isShow">
          <ul>
            <li v-for="(item,index) in menuList" :key="index" :class="'menu'+index" @click="goPath(item.path)">
              <i :class="'menuicon'+index"></i>
              <p>{{item.menuName}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../utils/request'
import * as uData from "../utils/data";
export default {
  name: "App",
  data() {
    return {
      processHandList: [
        // {
        //   processName: '求职者——注册简历流程',
        // }
      ],
      jobItem: 0,
      menuList: [
        {
          menuName: "我要找工作",
          path:'/job'
        },
        {
          menuName: "我要招人才",
          path:'/personnel'
        },
        {
          menuName: "普工专区",
          path:'/generalWorkers'
        },
        {
          menuName: "我要找零活",
          path:'/gigwork'
        },
        {
          menuName: "业主招零工",
          path:'/oddjobs'
        },
        {
          menuName: "最新政策",
          path:'/policy'
        },
        {
          menuName: "办事流程",
          path:'/processHand'
        },
        {
          menuName: "我要学技能",
          path:'/learnSkills'
        },
      ],
      isShow: false,
      search: {
        nid: 8,
        page: 1
      },
      loading: false,
      isEnd: false,
      id: 0,
      info: {
        content: ""
      }
    };
  },
  mounted() {
    uData.runIntervalNowFormatDate();
    this.getList();
  },
  beforeDestroy() {
    uData.clearIntervalNowFormatDate();
  },
  methods: {
    goPath(path){
      this.$router.push({path:path})
    },
    openModal(val) {
      this.jobItem = val;
      if (this.processHandList[val]) {
        this.id = this.processHandList[val]["id"];
        this.info = {content: ""};
        this.getDetail();
      }
    },
    getMenu() {
      this.isShow = !this.isShow
    },
    goHome(){
      this.$router.push({path:'/home'})
    },
    goback(){
      this.$router.push({path:'/home'})
    },
    getList() {
      let self = this;
      if (self.loading) {
        return false;
      }
      let params = this.search;
      self.loading = true;
      http.get('?m=fuwuProcessHand&c=index', {params: params}).then(function (res) {
        if (!res.data.error) {
          self.processHandList.push(...res.data.list);
        }
        if (self.search.page == 1 && self.processHandList.length) {
          self.id = self.processHandList[0]["id"];
          self.getDetail();
        }
      }).finally(function(){
        self.loading = false;
      });
    },
    getDetail() {
      let self = this;
      let params = {id: self.id};
      http.get('?m=fuwuProcessHand&c=detail', {params: params}).then(function (res) {
        if (!res.data.error) {
          self.info = res.data.info;
        } else {
          self.info = {content: res.data.msg};
        }
      });
    },
    handleScroll() {
      const container = this.$refs.scrollWrapper
      const scrollHeight = container.scrollHeight
      const scrollTop = container.scrollTop
      const clientHeight = container.clientHeight
      if (scrollTop + clientHeight >= scrollHeight && !this.loading) {
        this.loadMore()
      }
    },
    loadMore: function () {
      if (!this.isEnd) {
        this.search.page++;
        this.getList();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  margin-top: 4rem;
  border-bottom: 1px solid #fff;
}

.container {
  padding: 1.15rem 2.4rem;
  .processHandList {
    width: 8.4rem;
    height: 17.675rem;
    overflow-y: auto;
    background-color: #4c5dfc;
    float: left;
    padding: 0.3rem 0.325rem;

    li {
      list-style: none;
      height: 2.25rem;
      line-height: 2.25rem;
      text-align: center;
      margin-bottom: 0.35rem;
      border: 1px solid rgb(131, 251, 251);
      border-radius: 0.25rem;
      cursor: pointer;
      // position: relative;
      p {
        font-size: 0.5rem;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .active {
      background: url(../assets/images/indexImg/lictive.png) no-repeat;
      background-size: 100% 100%;
      height: 2.25rem;
      &::before {
        // content: "";
        // background: url(../assets/images/indexImg/arrow.png) no-repeat;
        // background-size: 100% 100%;
        // width: 0.55rem;
        // height: 0.575rem;
        // position: absolute;
        // left: 11.1rem;
        // margin-top: 0.8rem;
        // top:6.2rem;
      }
      p {
        font-weight: bold;
      }
    }
  }
  .processHandCon {
    width: calc(100% - 9.4rem);
    float: left;
    height: 17.675rem;
    overflow-y: auto;
    background-color: #4c5dfc;
    margin-left: 1rem;
    .control {
      font-size: 16px;
      color: #fff;
    }
  }

  .btn {
    .home {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      left: 2.4rem;
      bottom: 1.3rem;
      cursor: pointer;

      .homeicon {
        background: url("../assets/images/jobImg/hombig.png") no-repeat;
        background-size: 100% 100%;
        width: 1.125rem;
        height: 0.975rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.875rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .menu {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      left: 7.5rem;
      bottom: 1.3rem;
      cursor: pointer;

      .menuicon {
        background: url("../assets/images/jobImg/menuicon.png") no-repeat;
        background-size: 100% 100%;
        width: 0.975rem;
        height: 0.975rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.575rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .back {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      right: 2.4rem;
      bottom: 1.3rem;
      cursor: pointer;

      .backicon {
        background: url("../assets/images/jobImg/back.png") no-repeat;
        background-size: 100% 100%;
        width: 1.125rem;
        height: 1.025rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.875rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .menuBox {
      position: absolute;
      left: 12rem;
      bottom: 1.3rem;
      background: url("../assets/images/jobImg/menubg.png") no-repeat;
      background-size: 100% 100%;
      width: 25.75rem;
      height: 3.425rem;
      padding: 0.55rem 1.05rem 0.55rem 1.5rem;
      li {
        width: 2.05rem;
        height: 2.05rem;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.95rem;
        text-align: center;
        padding-top: 0.35rem;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        p {
          font-size: 0.3rem;
          color: #fff;
          margin-top: 0.4rem;
        }
        i {
          display: block;
          margin: 0 auto;
        }
        .menuicon0 {
          background: url("../assets/images/menuImg/menuicon1.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.6rem;
        }
        .menuicon1 {
          background: url("../assets/images/menuImg/menuicon2.png") no-repeat;
          background-size: 100% 100%;
          width: 0.6rem;
          height: 0.625rem;
        }
        .menuicon2 {
          background: url("../assets/images/menuImg/menuicon3.png") no-repeat;
          background-size: 100% 100%;
          width: 0.65rem;
          height: 0.65rem;
        }
        .menuicon3 {
          background: url("../assets/images/menuImg/menuicon4.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.65rem;
        }
        .menuicon4 {
          background: url("../assets/images/menuImg/menuicon5.png") no-repeat;
          background-size: 100% 100%;
          width: 0.6rem;
          height: 0.675rem;
        }
        .menuicon5 {
          background: url("../assets/images/menuImg/menuicon6.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.675rem;
        }
        .menuicon6 {
          background: url("../assets/images/menuImg/menuicon7.png") no-repeat;
          background-size: 100% 100%;
          width: 0.8rem;
          height: 0.7rem;
        }
        .menuicon7 {
          background: url("../assets/images/menuImg/menuicon8.png") no-repeat;
          background-size: 100% 100%;
          width: 0.7rem;
          height: 0.7rem;
        }
      }
      .menu0 {
        background: url("../assets/images/menuImg/menu1.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu1 {
        background: url("../assets/images/menuImg/menu2.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu2 {
        background: url("../assets/images/menuImg/menu3.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu3 {
        background: url("../assets/images/menuImg/menu4.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu4 {
        background: url("../assets/images/menuImg/menu5.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu5 {
        background: url("../assets/images/menuImg/menu6.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu6 {
        background: url("../assets/images/menuImg/menu7.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu7 {
        background: url("../assets/images/menuImg/menu8.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>