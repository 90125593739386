<template>
    <div class="frame">
        <div class="title">智慧就业创业综合服务平台<em>(找人才)</em></div>
        <div class="time">
            <p id="time"></p>
            <span id="date"></span>
            <span id="week" style="margin-left: 0.3rem"></span>
        </div>
        <div class="header"></div>
        <div class="container">
            <commonHeader :salaryList="salaryFaList" :salary="'经验：'" @search-head="searchParams"/>
            <div class="personnelList" ref="scrollWrapper"  @scroll="handleScroll">
                <ul>
                    <li v-for="(item, index) in personnelList" :key="index" @click="openModal(index)"
                        :class="{ 'active': jobItem == index }">
                        <div class="userImg">
                            <img :src="item.photo" alt="">
                        </div>
                        <div class="userInfo">
                            <p class="jobname">{{ item.username }}</p>
                            <p class="addre">{{ item.num }}<em>· </em>{{ item.experience }}<em>· </em>{{ item.record }}
                            </p>
                            <p class="status">{{ item.status }}</p>
                        </div>
                        <div class="companyInfo">
                            <p class="companyname">工作职能：{{ item.work }}</p>
                            <p class="companyname">工作经验：{{ item.workExprience }}</p>
                            <p class="companyname">期望薪资：{{ item.workMoney }}</p>
                        </div>
                        <div class="address">
                            <p>{{ item.address }}</p>
                        </div>
                        <div class="qrcode">
                            <img :src="item.qrcode" alt="">
                            <p>微信扫一扫联系我</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn">
                <button class="home" @click="goHome">
                    <i class="homeicon"></i>
                    <span>首页</span>
                </button>
                <button class="menu" @click="getMenu">
                    <i class="menuicon"></i>
                    <span>菜单</span>
                </button>
                <button class="back" @click="goback">
                    <i class="backicon"></i>
                    <span>返回</span>
                </button>
                <div class="menuBox" v-if="isShow">
                    <ul>
                        <li v-for="(item,index) in menuList" :key="index" :class="'menu'+index"
                            @click="goPath(item.path)">
                            <i :class="'menuicon'+index"></i>
                            <p>{{ item.menuName }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <Modal v-model="modal1" footer-hide>
            <div class="personCon">
                <div class="conier">
                    <div class="userImg">
                        <img :src="detailRow.photo" alt="">
                    </div>
                    <div class="info">
                        <p class="jobname">{{ detailRow.username }}</p>
                        <p class="addre">{{ detailRow.num }}<em>|</em>{{detailRow.experience}}<em>|</em>{{ detailRow.record }}</p>
                        <p class="money">期望薪资：{{detailRow.workMoney}}</p>
                    </div>
                </div>
                <div class="image">
                    <img :src="detailRow.qrcode" alt="">
                    <div class="box">微信扫一扫联系我</div>
                </div>

            </div>
        </Modal>
    </div>
</template>

<script>
import commonHeader from '../components/commonHeader.vue';
import http from "../utils/request";
import detail from "./detail.vue";
import * as uData from "../utils/data";

export default {
    name: "App",
    computed: {
        detail() {
            return detail
        }
    },
    components: {
        commonHeader
    },
    data() {
        return {
            personnelList: [],
            modal1: false,
            jobItem: 0,
            salaryFaList: [],
            menuList: [
                {
                    menuName: "我要找工作",
                    path: '/job'
                },
                {
                    menuName: "我要招人才",
                    path: '/personnel'
                },
                {
                    menuName: "普工专区",
                    path: '/generalWorkers'
                },
                {
                    menuName: "我要找零活",
                    path: '/gigwork'
                },
                {
                    menuName: "业主招零工",
                    path: '/oddjobs'
                },
                {
                    menuName: "最新政策",
                    path: '/policy'
                },
                {
                    menuName: "办事流程",
                    path: '/processHand'
                },
                {
                    menuName: "我要学技能",
                    path: '/learnSkills'
                },
            ],
            isShow: false,
            search: {
                page: 1
            },
            detailRow:{}
        };
    },
    mounted() {
        uData.runIntervalNowFormatDate();
        this.persionnel();
        this.getexp();
    },
    beforeDestroy() {
        uData.clearIntervalNowFormatDate();
    },
    methods: {
        persionnel: function () {
            let self = this;
            const params = this.search;
            http.get('?m=fuwuPersonnel&c=index', {params}).then(function (res) {
                self.personnelList.push(...res.data.personnelList);
                self.isEnd = res.data.isEnd;
            });
        },
        getexp:function(){
            let self = this;
            const params = this.search;
            http.get('?m=fuwuPublic&c=getExp').then(function (res) {
                self.salaryFaList = res.data;
            });
        },
        searchParams:function(e){
            this.search.job = e.positionId;
            this.search.city = e.cityId;
            this.search.whour =  e.salary;
            this.search.page = 1;
            this.personnelList = [];
            this.persionnel();
        },
        handleScroll() {

            const container = this.$refs.scrollWrapper
            const scrollHeight = container.scrollHeight
            const scrollTop = container.scrollTop
            const clientHeight = container.clientHeight
            if (scrollTop + clientHeight >= scrollHeight && !this.loading) {
                this.loadMore()
            }
        },
        loadMore:function(){
            if (!this.isEnd){
                this.search.page ++;
                this.persionnel();
            }
        },
        goPath(path) {
            this.$router.push({path: path})
        },
        openModal(val) {
            this.detailRow = this.personnelList[val];
            this.jobItem = val
            this.modal1 = true
        },
        getMenu() {
            this.isShow = !this.isShow
        },
        goHome() {
            this.$router.push({path: '/home'})
        },
        goback() {
            this.$router.push({path: '/home'})
        },
    }
};
</script>

<style lang="less" scoped>
.header {
  margin-top: 3.4rem;
  border-bottom: 1px solid #fff;
}

.container {
  padding: 0 2.4rem;

  .personnelList {
    width: 100%;
    height: 14.2rem;
    margin-top: 0.9rem;
    overflow-y: auto;
    padding: 0.25rem;

    li {
      position: relative;
      list-style: none;
      padding: 0.5rem 1.5rem;
      background-color: #4c5dfc;
      margin-bottom: 0.35rem;
    }

    .active {
      box-shadow: 0 0px 3px 4px rgba(255, 255, 255, 0.6);
    }

    em {
      margin: 0 0.125rem;
      font-style: normal;
      font-size: 0.45rem;
    }

    .userImg {
      float: left;

      img {
        width: 3.075rem;
        height: 3.075rem;
      }
    }

    .userInfo {
      width: 25%;
      float: left;
      margin-left: 0.6rem;

      .jobname {
        font-size: 1.05rem;
        color: #fff;
        font-family: "SourceHanSansSC";
      }

      .addre {
        font-size: 0.425rem;
        color: #0cff00;
        margin-top: 0.25rem;
        //  font-family: "SourceHanSansSC";
      }

      .status {
        font-size: 0.4rem;
        color: #fff;
        margin-top: 0.25rem;
      }
    }

    .companyInfo {
      padding-top: 0.3rem;
      display: inline-block;
      vertical-align: middle;
      width: 35%;

      .companyname {
        font-size: 0.45rem;
        color: #fff;
        line-height: 1rem;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .address {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      overflow: hidden;
      padding-left: 0.4rem;

      p {
        font-size: 0.5rem;
        color: #fff;
      }
    }

    .qrcode {
      position: absolute;
      top: 0.45rem;
      right: 1.5rem;
      text-align: center;

      img {
        width: 2.825rem;
        height: 2.8rem;
      }

      p {
        font-size: 0.35rem;
        color: #fff;
      }
    }
  }

  .btn {
    .home {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      left: 2.4rem;
      bottom: 1.3rem;
      cursor: pointer;

      .homeicon {
        background: url("../assets/images/jobImg/hombig.png") no-repeat;
        background-size: 100% 100%;
        width: 1.125rem;
        height: 0.975rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.875rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .menu {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      left: 7.5rem;
      bottom: 1.3rem;
      cursor: pointer;

      .menuicon {
        background: url("../assets/images/jobImg/menuicon.png") no-repeat;
        background-size: 100% 100%;
        width: 0.975rem;
        height: 0.975rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.575rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .back {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      right: 2.4rem;
      bottom: 1.3rem;
      cursor: pointer;

      .backicon {
        background: url("../assets/images/jobImg/back.png") no-repeat;
        background-size: 100% 100%;
        width: 1.125rem;
        height: 1.025rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.875rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .menuBox {
      position: absolute;
      left: 12rem;
      bottom: 1.3rem;
      background: url("../assets/images/jobImg/menubg.png") no-repeat;
      background-size: 100% 100%;
      width: 25.75rem;
      height: 3.425rem;
      padding: 0.55rem 1.05rem 0.55rem 1.5rem;
      li {
        width: 2.05rem;
        height: 2.05rem;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.95rem;
        text-align: center;
        padding-top: 0.35rem;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        p {
          font-size: 0.3rem;
          color: #fff;
          margin-top: 0.4rem;
        }
        i {
          display: block;
          margin: 0 auto;
        }
        .menuicon0 {
          background: url("../assets/images/menuImg/menuicon1.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.6rem;
        }
        .menuicon1 {
          background: url("../assets/images/menuImg/menuicon2.png") no-repeat;
          background-size: 100% 100%;
          width: 0.6rem;
          height: 0.625rem;
        }
        .menuicon2 {
          background: url("../assets/images/menuImg/menuicon3.png") no-repeat;
          background-size: 100% 100%;
          width: 0.65rem;
          height: 0.65rem;
        }
        .menuicon3 {
          background: url("../assets/images/menuImg/menuicon4.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.65rem;
        }
        .menuicon4 {
          background: url("../assets/images/menuImg/menuicon5.png") no-repeat;
          background-size: 100% 100%;
          width: 0.6rem;
          height: 0.675rem;
        }
        .menuicon5 {
          background: url("../assets/images/menuImg/menuicon6.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.675rem;
        }
        .menuicon6 {
          background: url("../assets/images/menuImg/menuicon7.png") no-repeat;
          background-size: 100% 100%;
          width: 0.8rem;
          height: 0.7rem;
        }
        .menuicon7 {
          background: url("../assets/images/menuImg/menuicon8.png") no-repeat;
          background-size: 100% 100%;
          width: 0.7rem;
          height: 0.7rem;
        }
      }
      .menu0 {
        background: url("../assets/images/menuImg/menu1.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu1 {
        background: url("../assets/images/menuImg/menu2.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu2 {
        background: url("../assets/images/menuImg/menu3.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu3 {
        background: url("../assets/images/menuImg/menu4.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu4 {
        background: url("../assets/images/menuImg/menu5.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu5 {
        background: url("../assets/images/menuImg/menu6.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu6 {
        background: url("../assets/images/menuImg/menu7.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu7 {
        background: url("../assets/images/menuImg/menu8.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
/deep/ .ivu-modal {
  background: url(../assets/images/jobImg/zrcbg.png) no-repeat;
  background-size: 100% 100%;
  width: 13.8rem;
  height: 15rem;
  margin-top: 4.25rem;
}
/deep/.ivu-modal-content {
  background: rgba(255, 255, 255, 0);
  box-shadow: none;
}
.personCon {
  text-align: center;
  padding-top: 0.675rem;
  .conier{
    overflow: hidden;
  }
  .userImg {
    float: left;
    padding-left: 1.25rem;
    img {
      width: 3.7rem;
      height: 3.7rem;
    }
  }
  .info{
    // float: left;
    text-align: left;
    margin-left: 0.6rem;
    margin-top: .25rem;
    position: relative;
    overflow: hidden;
    padding-left: 0.4rem;
  }
  .jobname {
    font-size: 1.05rem;
    color: #fff;
    font-family: "SourceHanSansSC";
  }
  .addre {
    font-size: 0.5rem;
    color: #fff;
  }
  em {
    margin: 0 0.125rem;
  }
  .money {
    color: #fff;
    font-size: 0.5rem;
  }
  img {
    width: 7.95rem;
    height: 7.95rem;
    padding-top: 0.25rem;
  }
  .box {
    background: url(../assets/images/jobImg/xqbox.png) no-repeat;
    background-size: 100% 100%;
    width: 10.9rem;
    height: 1.325rem;
    font-size: 0.85rem;
    font-weight: bold;
    color: #fff;
    margin: 0 auto;
  }
}
</style>