<template>
  <div class="frame frame1">
    <div class="title flex_center"><i class="title_logo"></i>智慧就业创业综合服务平台
    </div>
    <div class="time">
      <p id="time"></p>
      <span id="date"></span>
      <span id="week" style="margin-left: 0.3rem"></span>
    </div>
    <div class="main">
      <div class="upsection">
        <div class="left">
          <div class="wyzgz" @click="goJob()">
            <i class="wyzgz_icon"></i>
            <p>我要找工作</p>
          </div>
          <div class="wyzrc" @click="goperon()">
            <i class="wyzrc_icon"></i>
            <p>我要找人才</p>
          </div>
          <div class="pgzq" @click="gogener()">
            <i class="pgzq_icon"></i>
            <p>普工专区</p>
          </div>
        </div>
        <div class="right">
          <div class="wyzlh" @click="gogig()">
            <i class="wyzlh_icon">
            </i>
            <p>我要找零活</p>
          </div>
          <div class="yzzlg" @click="goodd()">
            <i class="yzzlg_icon">
            </i>
            <p>业主招零工</p>
          </div>
          <div class="wyxjn" @click="goskill()">
            <i class="wyxjn_icon"></i>
            <p>我要学技能</p>
          </div>
        </div>
        <div class="center">
          <div class="swiper-box">
            <Carousel v-model="value1" loop>
				
              <CarouselItem v-for="(item, adKey) in adarr" :key="adKey">
                <div class="demo-carousel">
                  <img :src="item.pic" alt="">
                </div>
              </CarouselItem>
              
            </Carousel>
          </div>
          <div class="dosection">
            <div class="zxzc" @click="gopolicy()">
              <i class="zxzc_icon"></i>
              <span>最新政策</span>
            </div>
            <div class="bslc" @click="goprocess()">
              <i class="bslc_icon"></i>
              <span>办事流程</span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <span>
          系统版本：v.2.1.0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;技术支持：兰州领新网络信息科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;甘肃工作圈人才咨询服务有限公司
        </span>
      </div>
    </div>
    <div style="z-index: -1;">
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(14, 31, 197,0.7)" />
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(14, 31, 197,0.5)" />
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(14, 31, 197,0.3)" />
          <use xlink:href="#gentle-wave" x="48" y="7" fill="rgba(14, 31, 197,1)" />
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
import http from '../utils/request';
import * as uData from '../utils/data';
export default {
  data() {
    return {
      value1: 0,
      adarr: [],
    };
  },
  mounted() {
    uData.runIntervalNowFormatDate();
    this.getinfo();
  },
  beforeDestroy() {
    uData.clearIntervalNowFormatDate();
  },
  methods: {
    getinfo: function () {
      let self = this;
      http.get('?m=fuwuIndex&c=index').then(function (res) {
        self.adarr = res.data.adarr;
      });
    },
    goJob() {
      this.$router.push({ path: '/job' })
    },
    goperon() {
      this.$router.push({ path: '/personnel' })
    },
    gogener() {
      this.$router.push({ path: '/generalWorkers' })
    },
    gogig() {
      this.$router.push({ path: '/gigwork' })
    },
    goodd() {
      this.$router.push({ path: '/oddjobs' })
    },
    goskill() {
      this.$router.push({ path: '/learnSkills' })
    },
    gopolicy() {
      this.$router.push({ path: '/policy' })
    },
    goprocess() {
      this.$router.push({ path: '/processHand' })
    },
  },
}
</script>
<style lang="less" scoped>
.frame .footer {
  z-index: 99999;
  span {
    font-size: 0.35rem;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    color: #fff;
    transform: translateX(-50%);
  }
}
.frame .main {
  width: 36.7rem;
  margin: 0 auto;
  padding-top: 5rem;
}

.frame .main .upsection {
  overflow: hidden;
}

.frame .main .upsection p {
  font-size: 0.8rem;
  color: #fff;
  cursor: pointer;
  font-family: "SourceHanSansSC", sans-serif;
  margin-top: 0.5rem;
}

.frame .main .upsection .left {
  float: left;
  width: 7.75rem;
}

.frame .main .upsection .left .wyzgz {
  background: url("../assets/images/indexImg/wyzgz.png") no-repeat;
  background-size: 100% 100%;
  width: 7.75rem;
  height: 5.5rem;
  text-align: center;
  padding-top: 0.675rem;
  cursor: pointer;
}

.frame .main .upsection .left .wyzgz .wyzgz_icon {
  background: url("../assets/images/indexImg/wyzgz_icon.png") no-repeat;
  background-size: 100% 100%;
  width: 2.175rem;
  height: 2.125rem;
  display: inline-block;
  margin: 0 auto;
}

.frame .main .upsection .left .wyzrc {
  background: url("../assets/images/indexImg/wyzrc.png") no-repeat;
  background-size: 100% 100%;
  width: 7.75rem;
  height: 5.5rem;
  text-align: center;
  padding-top: 0.675rem;
  margin-top: 0.8rem;
  cursor: pointer;
}

.frame .main .upsection .left .wyzrc .wyzrc_icon {
  background: url("../assets/images/indexImg/wyzrc_icon.png") no-repeat;
  background-size: 100% 100%;
  width: 2.175rem;
  height: 2.125rem;
  display: inline-block;
}
.frame .main .upsection .left .pgzq {
  background: url("../assets/images/indexImg/pgzq.png") no-repeat;
  background-size: 100% 100%;
  width: 7.75rem;
  height: 5.5rem;
  text-align: center;
  padding-top: 0.675rem;
  margin-top: 0.8rem;
  cursor: pointer;
}

.frame .main .upsection .left .pgzq .pgzq_icon {
  background: url("../assets/images/indexImg/pgzqicon.png") no-repeat;
  background-size: 100% 100%;
  width: 2.175rem;
  height: 2.125rem;
  display: inline-block;
}

.frame .main .upsection .center {
  margin-left: 8.65rem;
  margin-right: 8.65rem;

  .swiper-box {
    img {
      width: 100%;
      height: 11.75rem;
    }
  }
}

.frame .main .upsection .right {
  float: right;
  width: 7.75rem;
}

.frame .main .upsection .right .wyzlh {
  background: url("../assets/images/indexImg/wyzlh.png") no-repeat;
  background-size: 100% 100%;
  width: 7.75rem;
  height: 5.5rem;
  text-align: center;
  padding-top: 0.675rem;
  cursor: pointer;
}

.frame .main .upsection .right .wyzlh .wyzlh_icon {
  background: url("../assets/images/indexImg/wyzlh_icon.png") no-repeat;
  background-size: 100% 100%;
  width: 2.175rem;
  height: 2.125rem;
  display: inline-block;
  margin: 0 auto;
}

.frame .main .upsection .right .yzzlg {
  background: url("../assets/images/indexImg/yzzlg.png") no-repeat;
  background-size: 100% 100%;
  width: 7.75rem;
  height: 5.5rem;
  text-align: center;
  padding-top: 0.675rem;
  margin-top: 0.8rem;
  cursor: pointer;
}

.frame .main .upsection .right .yzzlg .yzzlg_icon {
  background: url("../assets/images/indexImg/yzzlg_icon.png") no-repeat;
  background-size: 100% 100%;
  width: 2.175rem;
  height: 2.125rem;
  display: inline-block;
  margin: 0;
}
.frame .main .upsection .right .wyxjn {
  background: url("../assets/images/indexImg/wyxjn.png") no-repeat;
  background-size: 100% 100%;
  width: 7.75rem;
  height: 5.5rem;
  text-align: center;
  padding-top: 0.675rem;
  margin-top: 0.8rem;
}

.frame .main .upsection .right .wyxjn_icon {
  background: url("../assets/images/indexImg/wyxjn_icon.png") no-repeat;
  background-size: 100% 100%;
  width: 2.1rem;
  height: 2.225rem;
  display: inline-block;
}

.dosection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0.7rem;

  .zxzc {
    background: url("../assets/images/indexImg/zxzc.png") no-repeat;
    background-size: 100% 100%;
    width: 9.25rem;
    height: 5.5rem;
    line-height: 5.5rem;
    text-align: center;
    cursor: pointer;
  }

  .zxzc_icon {
    background: url("../assets/images/indexImg/zxzc_icon.png") no-repeat;
    background-size: 100% 100%;
    width: 1.975rem;
    height: 2.225rem;
    display: inline-block;
    vertical-align: middle;
  }

  .zxzc span {
    font-size: 1.125rem;
    display: inline-block;
    color: #fff;
    font-family: "SourceHanSansSC", sans-serif;
    margin-left: 0.75rem;
  }

  .bslc {
    background: url("../assets/images/indexImg/bslc.png") no-repeat;
    background-size: 100% 100%;
    width: 9.25rem;
    height: 5.5rem;
    line-height: 5.5rem;
    margin-left: 0.8rem;
    text-align: center;
    cursor: pointer;
  }

  .bslc_icon {
    background: url("../assets/images/indexImg/bslc_icon.png") no-repeat;
    background-size: 100% 100%;
    width: 2.025rem;
    height: 1.8rem;
    display: inline-block;
    vertical-align: middle;
  }

  .bslc span {
    font-size: 1.125rem;
    display: inline-block;
    color: #fff;
    font-family: "SourceHanSansSC", sans-serif;
    margin-left: 0.45rem;
  }
}

.frame .main .dosection .right p {
  font-size: 0.8rem;
  color: #fff;
  cursor: pointer;
  font-family: "SourceHanSansSC", sans-serif;
}
</style>
<style lang="less" scoped>

.header {
  position: relative;
  text-align: center;
  background: linear-gradient(
    60deg,
    rgba(84, 58, 183, 1) 0%,
    rgba(0, 172, 193, 1) 100%
  );
  /* 	background: #FFAFBD;  
background: -webkit-linear-gradient(to right, #ffc3a0, #FFAFBD);  
background: linear-gradient(to right, #ffc3a0, #FFAFBD); 
*/
  color: white;
}

.logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.inner-header {
  height: 65vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
  z-index: -1;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  /* background-color: white; */
}

.content a {
  margin: 0 5px;
  font-size: 12px;
  color: #333;
}

.content a:hover {
  color: #000;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
.flex_center{
  display: flex;
  align-items: center;
}

.title_logo {
  background: url("../assets/images/indexImg/logo.png") no-repeat;
  background-size: 100% 100%;
  width: 2.025rem;
  height: 2.025rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  .content {
    height: 30vh;
  }

  h1 {
    font-size: 24px;
  }
}
</style>