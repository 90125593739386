var intervalNowFormatDate = null;
// 获取当前月；从当前月前推 monthLen
function getMonDynamic (monthLen) {
  var dataArr = []
  var data = new Date()
  // var year = data.getFullYear();
  data.setMonth(data.getMonth() + 1, 1)// 获取到当前月份,设置月份
  for (var i = 0; i < monthLen; i++) {
    data.setMonth(data.getMonth() - 1)// 每次循环一次 月份值减1
    var m
    if (data.getMonth() === 0) {
      m = 12 + '月'
    } else {
      m = data.getMonth() + '月'
    }

    // var m = data.getMonth() +1 '月'
    // m = m < 10 ? "0" + m : m;
    dataArr.unshift(m)
  }
  return dataArr // 只返回数字
}
// 从当前月后推
function monthFormat(monthLen) { // 带格式
  var dataArr = []
  var data = new Date()
  // var year = data.getFullYear()
  data.setMonth(data.getMonth() + 1, 1)// 获取到当前月份,设置月份
  for (var i = 0; i < monthLen; i++) {
    data.setMonth(data.getMonth() - 1)// 每次循环一次 月份值减1
    var m = data.getMonth() + 1
    m = m < 10 ? '0' + m : m
    dataArr.unshift(data.getFullYear() + '-' + (m))
  }
  return dataArr
}
function monthFormatcr(monthLen) {
  // 带格式
  var dataArr = []
  var data = new Date()
  // var year = data.getFullYear()
  data.setMonth(data.getMonth() + 1, 1) // 获取到当前月份,设置月份
  for (var i = 0; i < monthLen; i++) {
    data.setMonth(data.getMonth() - 1) // 每次循环一次 月份值减1
    var m = data.getMonth()
    var y = data.getFullYear()
    if (m === 0) {
      y = y - 1
    }
    m = m === 0 ? '12' : m

    dataArr.unshift(y + '-' + m)
  }
  return dataArr
}
// 获取当前日前：年-月-日
function dqDate(yearNum) {
  var t = new Date()
  var y = t.getFullYear()
  var m = t.getMonth
  // var r = t.getDate + 1
  let arr = y + '-' + m
  return arr
}
// 获取过去N年
function getYear (yearNum) {
  var t = new Date()
  var y = t.getFullYear()
  let arr = []
  for (var i = 0; i <= yearNum - 1; i++) {
    arr.unshift(y)
    y--
  }
  return arr
}

// 获取过去N年（格式：2012年）
function getYearges(yearNum) {
  var t = new Date()
  var y = t.getFullYear() - 1
  let arr = []
  for (var i = 0; i <= yearNum - 1; i++) {
    let yeargs = y + '年'
    arr.unshift(yeargs)
    y--
  }
  return arr
}
getYearges(5)

// 从上一个月往后推
function monthBefore(monthLen) {
  var dataArr = []
  var data = new Date()
  // var year = data.getFullYear()
  data.setMonth(data.getMonth(), 1)// 获取到当前月份,设置月份
  for (var i = 0; i < monthLen; i++) {
    data.setMonth(data.getMonth() - 1)// 每次循环一次 月份值减1
    var m = data.getMonth() + 1
    m = m < 10 ? '0' + m : m
    dataArr.unshift(data.getFullYear() + '-' + (m))
  }
  return dataArr
}

// 取24小时
function getDatahour () {
  var data = new Date()
  let curHour = data.getHours() + 1
  let passHour = 24 - curHour
  let hourArr = []
  for (let i = 0; i < curHour; i++) {
    hourArr.push(i)
  }
  for (let j = 1; j <= passHour; j++) {
    hourArr.unshift(24 - j)
  }
  console.log(hourArr)
  return hourArr
}
// 获取未来n天
let getFuturDay = (dayNum) => {
  let dayArr = []
  for (var i = 0; i < dayNum; i++) {
    var myDate = new Date()
    var milliseconds = myDate.getTime() + 1000 * 60 * 60 * 24 * i // 当i为0代表当前日期，为1时可以得到明天的日期，以此类推
    var newMyDate = new Date(milliseconds)
    var month = newMyDate.getMonth() + 1// 获取当前月
    var day = newMyDate.getDate() + 1// 获取当前日
    dayArr.push(month + '-' + day)
  }
  return dayArr
}
// 获取过去n天
let getPastDay = (dayNum) => {
  let dayArr = []
  for (var i = 0; i < dayNum; i++) {
    var myDate = new Date()
    var milliseconds = myDate.getTime() - 1000 * 60 * 60 * 24 * i // 当i为0代表当前日期，为1时可以得到明天的日期，以此类推
    var newMyDate = new Date(milliseconds)
    var month = newMyDate.getMonth() + 1// 获取当前月
    var day = newMyDate.getDate() + 1// 获取当前日
    dayArr.unshift(month + '-' + day)
  }
  return dayArr
}
// 获取["2016年", "2017年", "2018年", "2019年", "2020年1月-12月"]指定格式日期
function getYearMounthcr(lengthYe) {
  var datazj = []
  var t = new Date()
  var m = t.getMonth()
  var y = t.getFullYear()

  var h = 1
  var tmp
  if (m === 1) {
    tmp = y + '年' + m + '月'
  } else {
    tmp = y + '年' + h + '-' + m + '月'
  }
  for (var k = 0; k <= lengthYe; k++) {
    var yearzj = y-- + '年'
    datazj.unshift(yearzj)
  }
  datazj.pop()
  datazj.push(tmp)
  return datazj
}
// 获取当前时间  x年x月x日 00:00
function getDatenow (t) {
  // var t = new Date()
  var y = t.getUTCFullYear()
  var m = t.getMonth() + 1
  var d = t.getDate()
  var h = t.getHours()
  var minu = t.getMinutes()
  m = m < 10 ? '0' + m : m
  d = d < 10 ? '0' + d : d
  h = h < 10 ? '0' + h : h
  minu = minu < 10 ? '0' + minu : minu
  // var miao = t.getSeconds()
  return y + '年' + m + '月' + d + '日' + h + ':' + minu
}
function getNowFormatDate() {
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var dayn = date.getDate();
  var dayw = new Array(
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六"
  )[date.getDay()];
  var hour = addZero(date.getHours());
  let state = "";
  // 判断当前时间段
  if (hour >= 0 && hour <= 10) {
    state = `上午`;
  } else if (hour > 10 && hour <= 14) {
    state = `中午`;
  } else if (hour > 14 && hour <= 18) {
    state = `下午`;
  } else if (hour > 18 && hour <= 24) {
    state = `晚上`;
  }
  var minute = addZero(date.getMinutes());
  var second = addZero(date.getSeconds());

  if (document.getElementById("date")) {
    document.getElementById("date").innerHTML = year + "年" + month + "月" + dayn + "日";
  }
  if (document.getElementById("week")) {
    document.getElementById("week").innerHTML = dayw;
  }
  if (document.getElementById("time")) {
    document.getElementById("time").innerHTML = state + "    " + hour + ":" + minute;
  }
  // console.log(document.getElementById("time").innerHTML, "11111");
}
//时间显示优化（补“0”）
function addZero(fn) {
  return fn < 10 ? "0" + fn : fn;
}
function runIntervalNowFormatDate(){
  getNowFormatDate()
  if (intervalNowFormatDate == null) {
    intervalNowFormatDate = setInterval(getNowFormatDate, 6000);
  }
}
function clearIntervalNowFormatDate(){
  clearInterval(intervalNowFormatDate);
  intervalNowFormatDate = null;
}
export {
  intervalNowFormatDate,
  getMonDynamic,
  monthFormat,
  monthBefore,
  getDatahour,
  getFuturDay,
  getPastDay,
  getYearMounthcr,
  getYear,
  getYearges,
  monthFormatcr,
  dqDate,
  getDatenow,
  getNowFormatDate,
  runIntervalNowFormatDate,
  clearIntervalNowFormatDate
}
