<template>
    <div class="frame">
        <div class="title">智慧就业创业综合服务平台<em>(找工作)</em></div>
        <div class="time">
            <p id="time"></p>
            <span id="date"></span>
            <span id="week" style="margin-left: 0.3rem"></span>
        </div>
        <div class="header"></div>
        <div class="container">
            <commonHeader :salaryList="salaryFaList" :salary="'薪资：'" @search-head="searchParams"/>
            <div class="jobList" ref="scrollWrapper" @scroll="handleScroll">
                <ul>
                    <li v-for="(item, index) in jobList" :key="index" @click="openModal(index)"
                        :class="{ 'active': jobItem == index }">
                        <div class="userInfo">
                            <p class="jobname">{{ item.jobname }} <span>{{ item.money }}</span></p>
                            <p class="addre">{{ item.addre }}<em>|</em>{{ item.experience }}<em>|</em>{{ item.record }}
                            </p>
                            <ul>
                                <li v-for="(items, idx) in item.opition" :key="idx">{{ items.name }}</li>
                            </ul>
                        </div>
                        <div class="companyInfo">
                            <p class="companyname">{{ item.companyname }}</p>
                            <p class="trade">{{ item.trade }}<em>|</em>{{ item.enterprise }}<em>|</em>{{ item.number }}
                            </p>
                        </div>
                        <div class="qrcode">
                            <img :src="item.qrcode" alt="">
                            <p>微信扫一扫快速求职</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="loading">加载中...</div>
            <div class="btn">
                <button class="home" @click="goHome">
                    <i class="homeicon"></i>
                    <span>首页</span>
                </button>
                <button class="menu" @click="getMenu">
                    <i class="menuicon"></i>
                    <span>菜单</span>
                </button>
                <button class="back" @click="goback">
                    <i class="backicon"></i>
                    <span>返回</span>
                </button>
                <div class="menuBox" v-if="isShow">
                    <ul>
                        <li v-for="(item,index) in menuList" :key="index" :class="'menu'+index"
                            @click="goPath(item.path)">
                            <i :class="'menuicon'+index"></i>
                            <p>{{ item.menuName }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <Modal v-model="modal1" footer-hide>
            <div class="modalCon">
                <p class="jobname">{{ detail.jobname }}</p>
                <p class="addre">{{ detail.addre }}<em>|</em>{{ detail.experience }}<em>|</em>{{ detail.record }}</p>
                <p class="money">{{ detail.money }}</p>
                <img :src="detail.qrcode" alt="">
                <div class="box">微信扫一扫快速求职</div>
            </div>
        </Modal>
    </div>
</template>

<script>
import http from '../utils/request'
import commonHeader from '../components/commonHeader.vue';
import * as uData from "../utils/data";

export default {
    name: "App",
    components: {
        commonHeader
    },
    data() {
        return {
            jobList: [],
            modal1: false,
            jobItem: 0,
            menuList: [
                {
                    menuName: "我要找工作",
                    path: '/job'
                },
                {
                    menuName: "我要招人才",
                    path: '/personnel'
                },
                {
                    menuName: "普工专区",
                    path: '/generalWorkers'
                },
                {
                    menuName: "我要找零活",
                    path: '/gigwork'
                },
                {
                    menuName: "业主招零工",
                    path: '/oddjobs'
                },
                {
                    menuName: "最新政策",
                    path: '/policy'
                },
                {
                    menuName: "办事流程",
                    path: '/processHand'
                },
                {
                    menuName: "我要学技能",
                    path: '/learnSkills'
                },
            ],
            isShow: false,
            salaryFaList: [],
            search: {
                page: 1
            },
            loading: false,
            isEnd: false,
            detail: {},

        };
    },
    mounted() {
        uData.runIntervalNowFormatDate();
        this.getSalary();
        this.getJob();
    },
    beforeDestroy() {
        uData.clearIntervalNowFormatDate();
    },
    methods: {
        goPath(path) {
            this.$router.push({path: path})
        },
        openModal(val) {
            console.log(this.jobList[val]);
            this.detail = this.jobList[val];
            this.modal1 = true
        },
        getMenu() {
            this.isShow = !this.isShow
        },
        goHome() {
            this.$router.push({path: '/home'})
        },
        goback() {
            this.$router.push({path: '/home'})
        },
        getJob: function () {
            let self = this;
            const params = this.search;
            http.get('?m=fuwuJob&c=index', {params}).then(function (res) {
                self.jobList.push(...res.data.jobList);
                self.isEnd = res.data.isEnd;
            });
        },
        getSalary: function () {
            let self = this;
            http.get('?m=fuwuPublic&c=getSalary').then(function (res) {
                self.salaryFaList = res.data;
            });
        },
        searchParams: function (e) {
            this.search.job = e.positionId;
            this.search.city = e.cityId;
            this.search.salary = e.salary;
            this.search.page = 1;
            this.jobList = [];
            this.getJob();
        },
        handleScroll() {

            const container = this.$refs.scrollWrapper
            const scrollHeight = container.scrollHeight
            const scrollTop = container.scrollTop
            const clientHeight = container.clientHeight
            if (scrollTop + clientHeight >= scrollHeight && !this.loading) {
                this.loadMore()
            }
        },
        loadMore: function () {
            if (!this.isEnd) {
                this.search.page++;
                this.getJob();
            }
        }
    }
};
</script>

<style lang="less" scoped>
.header {
    margin-top: 3.4rem;
    border-bottom: 1px solid #fff;
}

.container {
    padding: 0 2.4rem;

    .jobList {
        width: 100%;
        height: 14.2rem;
        margin-top: 0.9rem;
        overflow-y: auto;
        padding: 0.25rem;

        li {
            position: relative;
            list-style: none;
            padding: 0.5rem 1.5rem;
            // padding-top: 0.7rem;
            background-color: rgb(76, 93, 252);
            margin-bottom: 0.35rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
        }

        .active {
            box-shadow: 0 0px 3px 4px rgba(255, 255, 255, 0.6);
        }

        em {
            margin: 0 0.25rem;
            font-style: normal;
        }

        .userInfo {
            display: inline-block;
            vertical-align: top;
            width: 50%;

            .jobname {
                font-size: 1.05rem;
                color: #fff;
                font-family: "SourceHanSansSC";

                span {
                    color: #0cff00;
                    padding-left: 0.75rem;
                }
            }

            .addre {
                font-size: 0.425rem;
                color: #fff;
            }

            ul {
                list-style: none;
                margin-top: 0.25rem;
            }

            li {
                list-style: none;
                display: inline-block;
                font-size: 0.35rem;
                color: #fff;
                padding: 0 0.25rem;
                background-color: rgb(38, 57, 235);
                margin-right: 0.325rem;
            }
        }

        .companyInfo {
            // padding-top: 0.3rem;
            display: inline-block;
            width: 40%;

            .companyname {
                font-size: 0.625rem;
                color: #fff;
            }

            .trade {
                font-size: 0.425rem;
                color: #fff;
                margin-top: 0.35rem;
            }
        }

        .qrcode {
            overflow: hidden;
            position: relative;
            width: max-content;
            // position: absolute;
            // top: 0.45rem;
            // right: 1.5rem;
            text-align: center;

            img {
                width: 2.825rem;
                height: 2.8rem;
                display: block;
                margin: auto
            }

            p {
                position: relative;
                overflow: hidden;
                font-size: 0.35rem;
                color: #fff;
                margin-top: 0.1rem;
            }
        }
    }

    .btn {
        .home {
            background-image: url("../assets/images/jobImg/btnbg.png");
            width: 4.575rem;
            height: 1.35rem;
            border-radius: 0.125rem;
            text-align: center;
            font-size: 0.725rem;
            color: #fff;
            position: absolute;
            left: 2.4rem;
            bottom: 1.3rem;
            cursor: pointer;

            .homeicon {
                background: url("../assets/images/jobImg/hombig.png") no-repeat;
                background-size: 100% 100%;
                width: 1.125rem;
                height: 0.975rem;
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.875rem;
            }

            span {
                display: inline-block;
                vertical-align: middle;
            }
        }

        .menu {
            background-image: url("../assets/images/jobImg/btnbg.png");
            width: 4.575rem;
            height: 1.35rem;
            border-radius: 0.125rem;
            text-align: center;
            font-size: 0.725rem;
            color: #fff;
            position: absolute;
            left: 7.5rem;
            bottom: 1.3rem;
            cursor: pointer;

            .menuicon {
                background: url("../assets/images/jobImg/menuicon.png") no-repeat;
                background-size: 100% 100%;
                width: 0.975rem;
                height: 0.975rem;
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.575rem;
            }

            span {
                display: inline-block;
                vertical-align: middle;
            }
        }

        .back {
            background-image: url("../assets/images/jobImg/btnbg.png");
            width: 4.575rem;
            height: 1.35rem;
            border-radius: 0.125rem;
            text-align: center;
            font-size: 0.725rem;
            color: #fff;
            position: absolute;
            right: 2.4rem;
            bottom: 1.3rem;
            cursor: pointer;

            .backicon {
                background: url("../assets/images/jobImg/back.png") no-repeat;
                background-size: 100% 100%;
                width: 1.125rem;
                height: 1.025rem;
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.875rem;
            }

            span {
                display: inline-block;
                vertical-align: middle;
            }
        }

        .menuBox {
            position: absolute;
            left: 12rem;
            bottom: 1.3rem;
            background: url("../assets/images/jobImg/menubg.png") no-repeat;
            background-size: 100% 100%;
            width: 25.75rem;
            height: 3.425rem;
            padding: 0.55rem 1.05rem 0.55rem 1.5rem;

            li {
                width: 2.05rem;
                height: 2.05rem;
                display: inline-block;
                vertical-align: top;
                margin-right: 0.95rem;
                text-align: center;
                padding-top: 0.35rem;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }

                p {
                    font-size: 0.3rem;
                    color: #fff;
                    margin-top: 0.4rem;
                }

                i {
                    display: block;
                    margin: 0 auto;
                }

                .menuicon0 {
                    background: url("../assets/images/menuImg/menuicon1.png") no-repeat;
                    background-size: 100% 100%;
                    width: 0.625rem;
                    height: 0.6rem;
                }

                .menuicon1 {
                    background: url("../assets/images/menuImg/menuicon2.png") no-repeat;
                    background-size: 100% 100%;
                    width: 0.6rem;
                    height: 0.625rem;
                }

                .menuicon2 {
                    background: url("../assets/images/menuImg/menuicon3.png") no-repeat;
                    background-size: 100% 100%;
                    width: 0.65rem;
                    height: 0.65rem;
                }

                .menuicon3 {
                    background: url("../assets/images/menuImg/menuicon4.png") no-repeat;
                    background-size: 100% 100%;
                    width: 0.625rem;
                    height: 0.65rem;
                }

                .menuicon4 {
                    background: url("../assets/images/menuImg/menuicon5.png") no-repeat;
                    background-size: 100% 100%;
                    width: 0.6rem;
                    height: 0.675rem;
                }

                .menuicon5 {
                    background: url("../assets/images/menuImg/menuicon6.png") no-repeat;
                    background-size: 100% 100%;
                    width: 0.625rem;
                    height: 0.675rem;
                }

                .menuicon6 {
                    background: url("../assets/images/menuImg/menuicon7.png") no-repeat;
                    background-size: 100% 100%;
                    width: 0.8rem;
                    height: 0.7rem;
                }

                .menuicon7 {
                    background: url("../assets/images/menuImg/menuicon8.png") no-repeat;
                    background-size: 100% 100%;
                    width: 0.7rem;
                    height: 0.7rem;
                }
            }

            .menu0 {
                background: url("../assets/images/menuImg/menu1.png") no-repeat;
                background-size: 100% 100%;
            }

            .menu1 {
                background: url("../assets/images/menuImg/menu2.png") no-repeat;
                background-size: 100% 100%;
            }

            .menu2 {
                background: url("../assets/images/menuImg/menu3.png") no-repeat;
                background-size: 100% 100%;
            }

            .menu3 {
                background: url("../assets/images/menuImg/menu4.png") no-repeat;
                background-size: 100% 100%;
            }

            .menu4 {
                background: url("../assets/images/menuImg/menu5.png") no-repeat;
                background-size: 100% 100%;
            }

            .menu5 {
                background: url("../assets/images/menuImg/menu6.png") no-repeat;
                background-size: 100% 100%;
            }

            .menu6 {
                background: url("../assets/images/menuImg/menu7.png") no-repeat;
                background-size: 100% 100%;
            }

            .menu7 {
                background: url("../assets/images/menuImg/menu8.png") no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}

/deep/ .ivu-modal {
    background: url(../assets/images/jobImg/zgzbg.png) no-repeat;
    background-size: 100% 100%;
    width: 13.8rem;
    height: 15rem;
    margin-top: 4.25rem;
}

/deep/ .ivu-modal-content {
    background: rgba(255, 255, 255, 0);
    box-shadow: none;
}

.modalCon {
    text-align: center;
    padding-top: 0.675rem;

    .jobname {
        font-size: 1.05rem;
        color: #fff;
        font-family: "SourceHanSansSC";
    }

    .addre {
        font-size: 0.425rem;
        color: #fff;
    }

    em {
        margin: 0 0.125rem;
    }

    .money {
        color: #fff;
        font-size: 1.125rem;
    }

    img {
        width: 7.95rem;
        height: 7.95rem;
        padding-top: 0.25rem;
    }

    .box {
        background: url(../assets/images/jobImg/xqbox.png) no-repeat;
        background-size: 100% 100%;
        width: 10.9rem;
        height: 1.325rem;
        font-size: 0.85rem;
        font-weight: bold;
        color: #fff;
        margin: 0 auto;
    }
}
</style>