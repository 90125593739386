<template>
  <div class="frame">
    <div class="title">智慧就业创业综合服务平台<em>(普工专区)</em></div>
    <div class="time">
      <p id="time"></p>
      <span id="date"></span>
      <span id="week" style="margin-left: 0.3rem"></span>
    </div>
    <div class="header"></div>
    <div class="container">
      <!-- <commonHeader /> -->

      <div class="generalList eee" ref="scrollContainer">
        <wv-pull-refresh ref='wvPullRefresh'  :scrollTop="scrollTop" @refresh="refresh">
          <ul>

            <li v-for="(item, index) in generalList" :key="index" @click="openModal(index, item)"
              :class="{ 'active': jobItem == index }">
              <div class="userInfo">
                <p class="jobname">{{ item.jobname }} <span>{{ item.money }}</span></p>
                <p class="addre">{{ item.addre }}<!-- <em>|</em>{{ item.record }} --></p>
                <ul v-if="item.opition.length > 0">
					<li v-for="(items, idx) in item.opition" :key="idx">{{ items }}</li>
                </ul>
              </div>
              <div class="companyInfo">
                <p class="companyname">{{ item.companyname }}</p>
                <!-- <p class="trade">{{ item.trade }}<em>|</em>{{ item.enterprise }}<em>|</em>{{ item.number }}人</p> -->
              </div>
              <div class="qrcode">
                <img :src="item.ewm" alt="">
                <p>微信扫一扫马上报名</p>
              </div>
            </li>

          </ul>
        </wv-pull-refresh>
      </div>

      <div class="btn">
        <button class="home" @click="goHome">
          <i class="homeicon"></i>
          <span>首页</span>
        </button>
        <button class="menu" @click="getMenu">
          <i class="menuicon"></i>
          <span>菜单</span>
        </button>
        <button class="back" @click="goback">
          <i class="backicon"></i>
          <span>返回</span>
        </button>
        <div class="menuBox" v-if="isShow">
          <ul>
            <li v-for="(item, index) in menuList" :key="index" :class="'menu' + index" @click="goPath(item.path)">
              <i :class="'menuicon' + index"></i>
              <p>{{ item.menuName }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Modal v-model="modal1" footer-hide>
      <div class="modalCon">
        <p class="jobname">{{ jobinfo.jobname }}</p>
        <p class="addre">{{ jobinfo.addre }}<!-- <em>|</em>初中及以上学历 --></p>
        <p class="money">{{ jobinfo.money }}</p>
        <img :src="jobinfo.ewm" alt="">
        <div class="box">微信扫一扫马上报名</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import wvPullRefresh from '../components/wvPullRefresh.vue';
import http from '../utils/request';
import * as uData from "../utils/data";
export default {
  name: "App",
  components: {
    wvPullRefresh
  },
  data() {
    return {
      generalList: [
        // {
        //   jobname: '生产员工',
        //   money: '5200-6000',
        //   addre: '广东- 佛山市',
        //   record: '初中及以上学历',
        //   companyname: '广东熠日照明科技有限公司',
        //   trade: '家具/家电/玩具/礼品',
        //   enterprise: '民营企业',
        //   number: '1000-9999人',
        //   opition: [
        //     {
        //       name: '环境好'
        //     },
        //     {
        //       name: '五险一金'
        //     },
        //     {
        //       name: '有提成'
        //     }
        //   ]
        // }
      ],
      jobinfo: {},
      scrollTop: 0,
      page: 1,
      valid: true,
      noMore: false,
      loading: false,
      modal1: false,
      jobItem: 0,
      salaryFaList: [
        {
          name: '全部'
        },
        {
          name: '一年以下'
        }, {
          name: '2-3年'
        }, {
          name: '4-6年'
        }, {
          name: '7-8年'
        }, {
          name: '9年以上'
        },
      ],
      menuList: [
        {
          menuName: "我要找工作",
          path: '/job'
        },
        {
          menuName: "我要招人才",
          path: '/personnel'
        },
        {
          menuName: "普工专区",
          path: '/generalWorkers'
        },
        {
          menuName: "我要找零活",
          path: '/gigwork'
        },
        {
          menuName: "业主招零工",
          path: '/oddjobs'
        },
        {
          menuName: "最新政策",
          path: '/policy'
        },
        {
          menuName: "办事流程",
          path: '/processHand'
        },
        {
          menuName: "我要学技能",
          path: '/learnSkills'
        },
      ],
      isShow: false,
    };
  },
  mounted() {
    uData.runIntervalNowFormatDate();
    this.getlist();
    this.$refs.scrollContainer.addEventListener('scroll', this.setTimer);
  },
  beforeDestroy() {
    uData.clearIntervalNowFormatDate();
    this.$refs.scrollContainer.removeEventListener('scroll', this.setTimer);
  },
  methods: {
    /**
    * 滚动到底部后，上拉一下再执行
    * @param fun 执行的方法
    * @param params 执行方法的参数
    */
    getMoreOfScroll: function (fun, params = {}) {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop = this.$refs.scrollContainer.scrollTop;
      this.scrollTop = scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight = this.$refs.scrollContainer.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight = this.$refs.scrollContainer.scrollHeight;
      //滚动条到底部的条件
      //这里减去15，让滚动条快到底部时就开始执行方法
      //由于没有滚动条还没有到底部时就开始执行方法，滚动过程中可能会多次执行方法，需要在组件使用时限制一下
      if (scrollTop + windowHeight >= scrollHeight - 15) {
        fun(params)
      }
    },
    //因为工具类中方法滚动条还没到达底部就开始执行，向下滚动过程中方法会执行很多次
    //这里使用函数节流的思路给限制一下防止频繁调用
    setTimer() {
      if (!this.valid) {
        return false
      }
      this.valid = false
      setTimeout(() => {
        this.getMore()
        this.valid = true;
      }, 500)
    },
    getMore() {
      this.getMoreOfScroll(this.nextpage)
    },

    refresh(done) {
      setTimeout(() => {
        this.getlist('refresh');
      }, 500)
    },
    nextpage: function () {
      if (!this.loading && !this.noMore) {
        this.page++;
        this.getlist();
      }
    },
    getlist(type = '') {
      var self = this;

      var params = {};
      if (type == 'refresh') {
        self.noMore = false;
        this.generalList = [];
        this.page = 1;
      }
      if (self.loading || self.noMore) {
        return false;
      }
      self.loading = true;

      params.page = this.page;
      http.get('?m=fuwuGenaral&c=index', { params }).then(function (res) {
        if (res.data.list.length > 0) {
          self.noMore = false;
          self.generalList.push(...res.data.list);
        } else {
          self.noMore = true;
        }
        self.loading = false;
        if (type == 'refresh') {
          self.$refs.wvPullRefresh.moveState = 0;
        }
      });
    },
    goPath(path) {
      this.$router.push({ path: path })
    },
    openModal(val, info) {
      this.modal1 = true
      this.jobItem = val
      this.jobinfo = info
    },
    getMenu() {
      this.isShow = !this.isShow
    },
    goHome() {
      this.$router.push({ path: '/home' })
    },
    goback() {
      this.$router.push({ path: '/home' })
    },
  }
};
</script>

<style lang="less" scoped>
.header {
  margin-top: 3.4rem;
  border-bottom: 1px solid #fff;
}

.container {
  padding: 0 2.4rem;

  .generalList {
    width: 100%;
    height: 19rem;
    margin-top: 0.9rem;
    overflow-y: auto;
    padding: 0.25rem;
    

    li {
      position: relative;
      list-style: none;
      padding: 0.7rem 1.5rem;
      // padding-top: 0.7rem;
      background-color: #4c5dfc;
      margin-bottom: 0.35rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
    }

    .active {
      box-shadow: 0 0px 3px 4px rgba(255, 255, 255, 0.6);
    }

    em {
      margin: 0 0.25rem;
      font-style: normal;
    }

    .userInfo {
      display: inline-block;
      vertical-align: top;
      width: 50%;

      .jobname {
        font-size: 1.05rem;
        color: #fff;
        font-family: "SourceHanSansSC";

        span {
          color: #0cff00;
          padding-left: 0.75rem;
        }
      }

      .addre {
        font-size: 0.425rem;
        color: #fff;
      }

      ul {
        list-style: none;
        margin-top: 0.25rem;
      }

      li {
        list-style: none;
        display: inline-block;
        font-size: 0.35rem;
        color: #fff;
        padding: 0 0.25rem;
        background-color: rgb(38, 57, 235);
        margin-right: 0.325rem;
      }
    }

    .companyInfo {
      padding-top: 0.3rem;
      display: inline-block;
      width: 35%;

      .companyname {
        font-size: 0.625rem;
        color: #fff;
      }

      .trade {
        font-size: 0.425rem;
        color: #fff;
        margin-top: 0.35rem;
      }
    }

    .qrcode {
      overflow: hidden;
      position: relative;
      width: max-content;
      text-align: center;

      img {
        width: 2.825rem;
        height: 2.8rem;
      }

      p {
        font-size: 0.35rem;
        color: #fff;
        margin-top: -0.125rem;
      }
    }
  }

  .btn {
    .home {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      left: 2.4rem;
      bottom: 1.3rem;
      cursor: pointer;

      .homeicon {
        background: url("../assets/images/jobImg/hombig.png") no-repeat;
        background-size: 100% 100%;
        width: 1.125rem;
        height: 0.975rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.875rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .menu {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      left: 7.5rem;
      bottom: 1.3rem;
      cursor: pointer;

      .menuicon {
        background: url("../assets/images/jobImg/menuicon.png") no-repeat;
        background-size: 100% 100%;
        width: 0.975rem;
        height: 0.975rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.575rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .back {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      right: 2.4rem;
      bottom: 1.3rem;
      cursor: pointer;

      .backicon {
        background: url("../assets/images/jobImg/back.png") no-repeat;
        background-size: 100% 100%;
        width: 1.125rem;
        height: 1.025rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.875rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .menuBox {
      position: absolute;
      left: 12rem;
      bottom: 1.3rem;
      background: url("../assets/images/jobImg/menubg.png") no-repeat;
      background-size: 100% 100%;
      width: 25.75rem;
      height: 3.425rem;
      padding: 0.55rem 1.05rem 0.55rem 1.5rem;

      li {
        width: 2.05rem;
        height: 2.05rem;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.95rem;
        text-align: center;
        padding-top: 0.35rem;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        p {
          font-size: 0.3rem;
          color: #fff;
          margin-top: 0.4rem;
        }

        i {
          display: block;
          margin: 0 auto;
        }

        .menuicon0 {
          background: url("../assets/images/menuImg/menuicon1.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.6rem;
        }

        .menuicon1 {
          background: url("../assets/images/menuImg/menuicon2.png") no-repeat;
          background-size: 100% 100%;
          width: 0.6rem;
          height: 0.625rem;
        }

        .menuicon2 {
          background: url("../assets/images/menuImg/menuicon3.png") no-repeat;
          background-size: 100% 100%;
          width: 0.65rem;
          height: 0.65rem;
        }

        .menuicon3 {
          background: url("../assets/images/menuImg/menuicon4.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.65rem;
        }

        .menuicon4 {
          background: url("../assets/images/menuImg/menuicon5.png") no-repeat;
          background-size: 100% 100%;
          width: 0.6rem;
          height: 0.675rem;
        }

        .menuicon5 {
          background: url("../assets/images/menuImg/menuicon6.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.675rem;
        }

        .menuicon6 {
          background: url("../assets/images/menuImg/menuicon7.png") no-repeat;
          background-size: 100% 100%;
          width: 0.8rem;
          height: 0.7rem;
        }

        .menuicon7 {
          background: url("../assets/images/menuImg/menuicon8.png") no-repeat;
          background-size: 100% 100%;
          width: 0.7rem;
          height: 0.7rem;
        }
      }

      .menu0 {
        background: url("../assets/images/menuImg/menu1.png") no-repeat;
        background-size: 100% 100%;
      }

      .menu1 {
        background: url("../assets/images/menuImg/menu2.png") no-repeat;
        background-size: 100% 100%;
      }

      .menu2 {
        background: url("../assets/images/menuImg/menu3.png") no-repeat;
        background-size: 100% 100%;
      }

      .menu3 {
        background: url("../assets/images/menuImg/menu4.png") no-repeat;
        background-size: 100% 100%;
      }

      .menu4 {
        background: url("../assets/images/menuImg/menu5.png") no-repeat;
        background-size: 100% 100%;
      }

      .menu5 {
        background: url("../assets/images/menuImg/menu6.png") no-repeat;
        background-size: 100% 100%;
      }

      .menu6 {
        background: url("../assets/images/menuImg/menu7.png") no-repeat;
        background-size: 100% 100%;
      }

      .menu7 {
        background: url("../assets/images/menuImg/menu8.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}

/deep/ .ivu-modal {
  background: url(../assets/images/jobImg/pgbg.png) no-repeat;
  background-size: 100% 100%;
  width: 13.8rem;
  height: 15rem;
  margin-top: 4.25rem;
}

/deep/.ivu-modal-content {
  background: rgba(255, 255, 255, 0);
  box-shadow: none;
}

.modalCon {
  text-align: center;
  padding-top: .675rem;

  .jobname {
    font-size: 1.05rem;
    color: #fff;
    font-family: "SourceHanSansSC";
  }

  .addre {
    font-size: 0.425rem;
    color: #fff;
  }

  em {
    margin: 0 .125rem;
  }

  .money {
    color: #fff;
    font-size: 1.125rem;
  }

  img {
    width: 7.95rem;
    height: 7.95rem;
    padding-top: .25rem;
  }

  .box {
    background: url(../assets/images/jobImg/xqbox.png) no-repeat;
    background-size: 100% 100%;
    width: 10.9rem;
    height: 1.325rem;
    font-size: .85rem;
    font-weight: bold;
    color: #fff;
    margin: 0 auto;
  }
}


</style>