<template>
  <div class="select">
    <div class="jobSelect">
      <div class="position">
        {{jobName}}
      </div>
      <div class="jobLi">
        <ul>
          <li v-for="(item, index) in positionList" :key="index" :class="{ 'active': navItem1 == index }" @click="getData1(index)">
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="more" @click="open">
        <span>更多</span>
        <span v-if="isShow">∨</span>
        <span v-else>∧</span>
      </div>
    </div>
    <div class="jobSelect">
      <div class="position">
        <span>地点：</span>
      </div>
      <div class="jobLi">
        <ul>
          <li v-for="(item, index) in addressList" :key="index" :class="{ 'active': navItem2 == index }" @click="getData2(index)">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="jobSelect" v-if="salaryList.length>0">
      <div class="position">
        <span>{{ salary }}</span>
      </div>
      <div class="jobLi">
        <ul>
          <li v-for="(item, index) in salaryList" :key="index" :class="{ 'active': navItem3 == index }" @click="getData3(index)">
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../utils/request";

export default {
  data() {
    return {
      jobName: '职业：',
      positionList: [],
      positionListall:[],
      navItem1: 0,
      navItem2: 0,
      navItem3: 0,
      addressList: [],
      isShow:true,
        sendArr :{}
    }
  },
    props: {
        salaryList: {
            type: Array,
            required: true
        },
        salary: {
            type: String,
            default: ''
        }
    },
    methods: {
        open() {
            if (this.isShow) {
                this.positionList = this.positionListall
                this.isShow = false
            } else {
                this.positionList = this.positionListall.slice(0, 6)
                this.isShow = true
            }
        },
        getData1(e) {
            this.sendArr.positionId =  this.positionListall[e]['id']
            this.navItem1 = e
            this.$emit("search-head",this.sendArr);

        },
        getData2(e) {
            this.navItem2 = e
            this.sendArr.cityId = this.addressList[e]['id']
            this.$emit("search-head",this.sendArr);
        },
        getData3(e) {
            this.navItem3 = e
            this.sendArr.salary = this.salaryList[e]['value']

            this.$emit("search-head",this.sendArr);
        },
        getCity: function () {
            let self = this;
            http.get('?m=fuwuPublic&c=cityList').then(function (res) {
                self.addressList = res.data;
            });
        },
        getPosition:function(){
            let self = this;
            http.get('?m=fuwuPublic&c=getPosition').then(function (res) {
                self.positionListall = res.data;
                self.positionList = self.positionListall.slice(0,6)

            });
        }
    },
    mounted(){
        this.getCity();
        this.getPosition();
    }
}
</script>

<style lang="less" scoped>
.select {
  margin-top: 0.9rem;
  .jobSelect {
    margin-bottom: .4rem;
    overflow: hidden;
    .position {
      width: 2.25rem;
      height: 33px;
      line-height: 33px;
      text-align: right;
      float: left;
      font-weight: bold;
      font-size: 0.625rem;
      color: #fff;
      font-family: "微软雅黑";
    }
    .jobLi {
      float: left;
      width: 90%;
      margin-top: 2px;
      li {
        display: inline-block;
        margin: 0 .55rem .25rem;
        padding: 0 .25rem;
        font-size: 0.5rem;
        color: #fff;
        font-family: "微软雅黑";
        cursor: pointer;
      }
      .active {
        background-color: #4c5dfc;
        padding: 0 0.25rem;
        border-radius: 0.125rem;
      }
    }
    .more{
      float: right;
      width: 1.3rem;
      text-align: center;
      border: 2px solid #7ff8fb;
      padding: 0 .05rem;
      border-radius: .125rem;
      cursor: pointer;
      span{
        font-size: .35rem;
        color: #7ff8fb;
      }
    }
  }
}
</style>