<template>
	<div class="frame">
		<div class="title">智慧就业创业综合服务平台<em>(找零活)</em></div>
		<div class="time">
			<p id="time"></p>
			<span id="date"></span>
			<span id="week" style="margin-left: 0.3rem"></span>
		</div>
		<div class="header"></div>
		<div class="container">
			<!--
			<div class="select">
				<div class="jobSelect">
					<div class="position">
						<span>地点：</span>
					</div>
					<div class="jobLi">
						<ul>
							<li v-for="(item, index) in addressList" :key="index"
								:class="{ 'active': navItem2 == index }" @click="getData2(index)">
								{{ item.name }}
							</li>
						</ul>
					</div>
				</div>
			</div>
			-->
			<div class="gigworkList" ref="scrollContainer">
				<ul>
					<li v-for="(item, index) in gigworkList" :key="index" @click="openModal(index)"
						:class="{ 'active': jobItem == index }">
						<div class="userInfo">
							<p class="jobname">{{ item.name }}</p>
							<!--
							<p class="addre">{{ item.addre }}</p>
							<ul>
								<li v-for="(items, idx) in item.opition" :key="idx">{{ items.name }}</li>
							</ul>
							-->
						</div>
						<div class="companyInfo">
							<p class="companyname">{{ item.salary }}</p>
						</div>
						<div class="workTime">
							<p class="workTimeName">{{ item.edate }}</p>
						</div>
						<div class="qrcode">
							<img :src="item.qrcode" alt="">
							<p>微信扫一扫快速求职</p>
						</div>
					</li>
				</ul>
			</div>
			<div class="btn">
				<button class="home" @click="goHome">
					<i class="homeicon"></i>
					<span>首页</span>
				</button>
				<button class="menu" @click="getMenu">
					<i class="menuicon"></i>
					<span>菜单</span>
				</button>
				<button class="back" @click="goback">
					<i class="backicon"></i>
					<span>返回</span>
				</button>
				<div class="menuBox" v-if="isShow">
					<ul>
						<li v-for="(item,index) in menuList" :key="index" :class="'menu'+index"
							@click="goPath(item.path)">
							<i :class="'menuicon'+index"></i>
							<p>{{item.menuName}}</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<Modal v-model="modal1" footer-hide>
			<div class="gigCon">
				<p class="jobname">{{ detail.name }}</p>
				<p class="addre">{{ detail.salary }}</p>
				<p class="money">{{ detail.edate }}</p>
				<img :src="detail.qrcode" alt="">
				<div class="box">微信扫一扫快速求职</div>
			</div>
		</Modal>
	</div>
</template>

<script>
	// import commonHeader from '../components/commonHeader.vue';
	import http from '../utils/request'
  import * as uData from "../utils/data";
	export default {
		name: "App",
		components: {
			// commonHeader
		},
		data() {
			return {
				loading: false,
				noMore: false,
				page: 1, // 页码
				gigworkList: [],
				modal1: false,
				jobItem: 0,
				detail: {},
				menuList: [{
						menuName: "我要找工作",
						path: '/job'
					},
					{
						menuName: "我要招人才",
						path: '/personnel'
					},
					{
						menuName: "普工专区",
						path: '/generalWorkers'
					},
					{
						menuName: "我要找零活",
						path: '/gigwork'
					},
					{
						menuName: "业主招零工",
						path: '/oddjobs'
					},
					{
						menuName: "最新政策",
						path: '/policy'
					},
					{
						menuName: "办事流程",
						path: '/processHand'
					},
					{
						menuName: "我要学技能",
						path: '/learnSkills'
					},
				],
				isShow: false,
				salaryFaList: [{
						name: '全部'
					},
					{
						name: '50以下'
					}, {
						name: '50-150'
					}, {
						name: '150-300'
					}, {
						name: '300以上'
					},
				],
				navItem2: 0,
				addressList: [{
						name: '全部'
					},
					{
						name: '城关区'
					}, {
						name: '安宁区'
					}, {
						name: '七里河区'
					}, {
						name: '西固区'
					}, {
						name: '红古区'
					},
					{
						name: '兰州新区'
					},
					{
						name: '兰州周边'
					},
				],
			};
		},
		mounted() {
      uData.runIntervalNowFormatDate();
			this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
		},
		beforeDestroy() {
			uData.clearIntervalNowFormatDate();
			this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
		},
		created() {
			this.getList()
		},
		methods: {
			handleScroll() {
				const scrollContainer = this.$refs.scrollContainer;
				const scrollTop = scrollContainer.scrollTop;
				const scrollHeight = scrollContainer.scrollHeight;
				const clientHeight = scrollContainer.clientHeight;

				// 检查是否滚动到底部（或接近底部）
				if (scrollTop + clientHeight >= scrollHeight - 50) {
					console.log('已经滚动到底部附近');
					this.getList();
				}
			},
			getList() {
				let that = this;
				if (that.loading || that.noMore) {
					return false;
				}
				that.loading = true;
				
				http.get('?m=fuwuGigwork',  {
					params: {page: that.page++},
				}).then(function(res) {
					let list = that.gigworkList;
					if (res.data.list && res.data.list.length > 0) {
						that.gigworkList = list.concat(res.data.list);
					} else if (that.gigworkList.length > 0) {
						that.noMore = true;
					}
					that.loading = false;
				});
			},
			goPath(path) {
				this.$router.push({
					path: path
				})
			},
			openModal(val) {
				this.modal1 = true
				this.jobItem = val
				this.detail = this.gigworkList[val]
			},
			getMenu() {
				this.isShow = !this.isShow
			},
			goHome() {
				this.$router.push({
					path: '/home'
				})
			},
			goback() {
				this.$router.push({
					path: '/home'
				})
			},
			getData2(e) {
				this.navItem2 = e
			},
		}
	};
</script>

<style lang="less" scoped>
	.header {
		margin-top: 3.4rem;
		border-bottom: 1px solid #fff;
	}

	.container {
		padding: 0 2.4rem;

		.select {
			margin-top: 0.9rem;

			.jobSelect {
				margin-bottom: .4rem;
				overflow: hidden;

				.position {
					width: 2.25rem;
					height: 33px;
					line-height: 33px;
					text-align: right;
					float: left;
					font-weight: bold;
					font-size: 0.625rem;
					color: #fff;
					font-family: "微软雅黑";
				}

				.jobLi {
					float: left;
					width: 90%;
					margin-top: 2px;

					li {
						display: inline-block;
						margin: 0 .55rem .25rem;
						padding: 0 .25rem;
						font-size: 0.5rem;
						color: #fff;
						font-family: "微软雅黑";
						cursor: pointer;
					}

					.active {
						background-color: #4c5dfc;
						padding: 0 0.25rem;
						border-radius: 0.125rem;
					}
				}
			}
		}

		.gigworkList {
			width: 100%;
			height: 17rem;
			margin-top: 0.9rem;
			overflow-y: auto;
			padding: 0.25rem;

			li {
				position: relative;
				list-style: none;
				padding: 0.5rem 1.5rem;
				background-color: #4c5dfc;
				margin-bottom: 0.35rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				overflow: hidden;
			}

			.active {
				box-shadow: 0 0px 3px 4px rgba(255, 255, 255, 0.6);
			}

			.userInfo {
				display: inline-block;
				vertical-align: middle;
				width: 40%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				.jobname {
					font-size: 1.05rem;
					color: #fff;
					font-family: "SourceHanSansSC";
				}

				.addre {
					font-size: 0.425rem;
					color: #fff;
				}

				ul {
					list-style: none;
					margin-top: 0.25rem;
				}

				li {
					list-style: none;
					display: inline-block;
					font-size: 0.35rem;
					color: #fff;
					padding: 0 0.25rem;
					background-color: rgb(38, 57, 235);
					margin-right: 0.325rem;
				}
			}

			.companyInfo {
				padding-top: 0.3rem;
				display: inline-block;
				vertical-align: middle;
				width: 25%;

				.companyname {
					font-size: 1.025rem;
					color: #0cff00;
				}
			}

			.workTime {
				display: inline-block;
				width: 20%;
				vertical-align: middle;

				.workTimeName {
					font-size: 0.625rem;
					color: #fff;
				}
			}

			.qrcode {
				overflow: hidden;
				position: relative;
				width: max-content;
				text-align: center;

				img {
					width: 2.825rem;
					height: 2.8rem;
					display: block;
					margin: auto
				}

				p {
					font-size: 0.35rem;
					color: #fff;
				}
			}
		}

		.btn {
			.home {
				background-image: url("../assets/images/jobImg/btnbg.png");
				width: 4.575rem;
				height: 1.35rem;
				border-radius: 0.125rem;
				text-align: center;
				font-size: 0.725rem;
				color: #fff;
				position: absolute;
				left: 2.4rem;
				bottom: 1.3rem;
				cursor: pointer;

				.homeicon {
					background: url("../assets/images/jobImg/hombig.png") no-repeat;
					background-size: 100% 100%;
					width: 1.125rem;
					height: 0.975rem;
					display: inline-block;
					vertical-align: middle;
					margin-right: 0.875rem;
				}

				span {
					display: inline-block;
					vertical-align: middle;
				}
			}

			.menu {
				background-image: url("../assets/images/jobImg/btnbg.png");
				width: 4.575rem;
				height: 1.35rem;
				border-radius: 0.125rem;
				text-align: center;
				font-size: 0.725rem;
				color: #fff;
				position: absolute;
				left: 7.5rem;
				bottom: 1.3rem;
				cursor: pointer;

				.menuicon {
					background: url("../assets/images/jobImg/menuicon.png") no-repeat;
					background-size: 100% 100%;
					width: 0.975rem;
					height: 0.975rem;
					display: inline-block;
					vertical-align: middle;
					margin-right: 0.575rem;
				}

				span {
					display: inline-block;
					vertical-align: middle;
				}
			}

			.back {
				background-image: url("../assets/images/jobImg/btnbg.png");
				width: 4.575rem;
				height: 1.35rem;
				border-radius: 0.125rem;
				text-align: center;
				font-size: 0.725rem;
				color: #fff;
				position: absolute;
				right: 2.4rem;
				bottom: 1.3rem;
				cursor: pointer;

				.backicon {
					background: url("../assets/images/jobImg/back.png") no-repeat;
					background-size: 100% 100%;
					width: 1.125rem;
					height: 1.025rem;
					display: inline-block;
					vertical-align: middle;
					margin-right: 0.875rem;
				}

				span {
					display: inline-block;
					vertical-align: middle;
				}
			}

			.menuBox {
				position: absolute;
				left: 12rem;
				bottom: 1.3rem;
				background: url("../assets/images/jobImg/menubg.png") no-repeat;
				background-size: 100% 100%;
				width: 25.75rem;
				height: 3.425rem;
				padding: 0.55rem 1.05rem 0.55rem 1.5rem;

				li {
					width: 2.05rem;
					height: 2.05rem;
					display: inline-block;
					vertical-align: top;
					margin-right: 0.95rem;
					text-align: center;
					padding-top: 0.35rem;
					cursor: pointer;

					&:last-child {
						margin-right: 0;
					}

					p {
						font-size: 0.3rem;
						color: #fff;
						margin-top: 0.4rem;
					}

					i {
						display: block;
						margin: 0 auto;
					}

					.menuicon0 {
						background: url("../assets/images/menuImg/menuicon1.png") no-repeat;
						background-size: 100% 100%;
						width: 0.625rem;
						height: 0.6rem;
					}

					.menuicon1 {
						background: url("../assets/images/menuImg/menuicon2.png") no-repeat;
						background-size: 100% 100%;
						width: 0.6rem;
						height: 0.625rem;
					}

					.menuicon2 {
						background: url("../assets/images/menuImg/menuicon3.png") no-repeat;
						background-size: 100% 100%;
						width: 0.65rem;
						height: 0.65rem;
					}

					.menuicon3 {
						background: url("../assets/images/menuImg/menuicon4.png") no-repeat;
						background-size: 100% 100%;
						width: 0.625rem;
						height: 0.65rem;
					}

					.menuicon4 {
						background: url("../assets/images/menuImg/menuicon5.png") no-repeat;
						background-size: 100% 100%;
						width: 0.6rem;
						height: 0.675rem;
					}

					.menuicon5 {
						background: url("../assets/images/menuImg/menuicon6.png") no-repeat;
						background-size: 100% 100%;
						width: 0.625rem;
						height: 0.675rem;
					}

					.menuicon6 {
						background: url("../assets/images/menuImg/menuicon7.png") no-repeat;
						background-size: 100% 100%;
						width: 0.8rem;
						height: 0.7rem;
					}

					.menuicon7 {
						background: url("../assets/images/menuImg/menuicon8.png") no-repeat;
						background-size: 100% 100%;
						width: 0.7rem;
						height: 0.7rem;
					}
				}

				.menu0 {
					background: url("../assets/images/menuImg/menu1.png") no-repeat;
					background-size: 100% 100%;
				}

				.menu1 {
					background: url("../assets/images/menuImg/menu2.png") no-repeat;
					background-size: 100% 100%;
				}

				.menu2 {
					background: url("../assets/images/menuImg/menu3.png") no-repeat;
					background-size: 100% 100%;
				}

				.menu3 {
					background: url("../assets/images/menuImg/menu4.png") no-repeat;
					background-size: 100% 100%;
				}

				.menu4 {
					background: url("../assets/images/menuImg/menu5.png") no-repeat;
					background-size: 100% 100%;
				}

				.menu5 {
					background: url("../assets/images/menuImg/menu6.png") no-repeat;
					background-size: 100% 100%;
				}

				.menu6 {
					background: url("../assets/images/menuImg/menu7.png") no-repeat;
					background-size: 100% 100%;
				}

				.menu7 {
					background: url("../assets/images/menuImg/menu8.png") no-repeat;
					background-size: 100% 100%;
				}
			}
		}
	}

	/deep/ .ivu-modal {
		background: url(../assets/images/jobImg/lhbg.png) no-repeat;
		background-size: 100% 100%;
		width: 13.8rem;
		height: 15rem;
		margin-top: 4.25rem;
	}

	/deep/.ivu-modal-content {
		background: rgba(255, 255, 255, 0);
		box-shadow: none;
	}

	.gigCon {
		text-align: center;
		padding-top: 0.675rem;

		.jobname {
			font-size: 1.125rem;
			color: #fff;
			font-family: "SourceHanSansSC";
		}

		.addre {
			font-size: 1.125rem;
			color: #fff;
		}

		em {
			margin: 0 0.125rem;
		}

		.money {
			color: #fff;
			font-size: 0.45rem;
		}

		img {
			width: 7.95rem;
			height: 7.95rem;
			padding-top: 0.25rem;
		}

		.box {
			background: url(../assets/images/jobImg/xqbox.png) no-repeat;
			background-size: 100% 100%;
			width: 10.9rem;
			height: 1.325rem;
			font-size: 0.85rem;
			font-weight: bold;
			color: #fff;
			margin: 0 auto;
		}
	}
</style>