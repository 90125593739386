import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Home from "../views/Home.vue";
import policy from "../views/policy.vue";
import Main from "../views/Main.vue";
import job from "../views/job.vue";
import oddjobs from "../views/oddjobs.vue";
import gigwork from "../views/gigwork.vue";
import personnel from "../views/personnel.vue";
import processHand from "../views/processHand.vue";
import generalWorkers from "../views/generalWorkers.vue";
import learnSkills from "../views/learnSkills.vue";
import detail from "../views/detail.vue";



const routes = [
  { path: "/home", component: Home },
  { path: "/job", name: "job", component: job },
  // { path: '/mall/:id',name:'Mall', component: Mall },
  { path: "/policy", component: policy },
  { path: "/oddjobs", component: oddjobs },
  { path: "/gigwork", component: gigwork },
  { path: "/personnel", component: personnel },
  { path: "/processHand", component: processHand },
  { path: "/generalWorkers", component: generalWorkers },
  { path: "/learnSkills", component: learnSkills },
  { path: "/detail", component: detail },
  {path: '/', redirect: '/home'}
];
const router = new VueRouter({
    routes, // (缩写) 相当于 routes: routes
});
//重复点击路由报错问题处理
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);
export default router;
