<template>
  <div class="frame">
    <div class="title">智慧就业创业综合服务平台<em>(学技能)</em></div>
    <div class="time">
      <p id="time"></p>
      <span id="date"></span>
      <span id="week" style="margin-left: 0.3rem"></span>
    </div>
    <div class="header"></div>
    <div class="container">
      <div class="skillsList">
        <ul>
          <li v-for="(item, index) in skillsList" :key="index" @click="openModal(item, index)" :class="{ 'active': jobItem == index }">
            <div class="userInfo">
              <img :src="item.cover_n" alt="">
              <div class="text">
                <p class="jobname">{{ item.title }}</p>
                <p class="addre">{{ item.desc }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="btn">
        <button class="home" @click="goHome">
          <i class="homeicon"></i>
          <span>首页</span>
        </button>
        <button class="menu" @click="getMenu">
          <i class="menuicon"></i>
          <span>菜单</span>
        </button>
        <button class="back" @click="goback">
          <i class="backicon"></i>
          <span>返回</span>
        </button>
        <div class="menuBox" v-if="isShow">
          <ul>
            <li v-for="(item,index) in menuList" :key="index" :class="'menu'+index" @click="goPath(item.path)">
              <i :class="'menuicon'+index"></i>
              <p>{{item.menuName}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Modal v-model="showqrcode" footer-hide>
      <div class="modalCon">
        <p class="jobname">{{title}}</p>
        <img :src="qrcode" alt="">
        <div class="box">微信扫一扫立即咨询</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import http from '../utils/request';
import * as uData from "../utils/data";
export default {
  name: "App",
  data() {
    return {
		skillsList: [],
		showqrcode: false,
		menuList: [
			{
				menuName: "我要找工作",
				path:'/job'
			},
			{
				menuName: "我要招人才",
				path:'/personnel'
			},
			{
				menuName: "普工专区",
				path:'/generalWorkers'
			},
			{
				menuName: "我要找零活",
				path:'/gigwork'
			},
			{
				menuName: "业主招零工",
				path:'/oddjobs'
			},
			{
				menuName: "最新政策",
				path:'/policy'
			},
			{
				menuName: "办事流程",
				path:'/processHand'
			},
			{
				menuName: "我要学技能",
				path:'/learnSkills'
			},
		],
		isShow: false,
		jobItem: 0,
		qrcode:'',
		title:'',
    };
  },
  mounted() {
    uData.runIntervalNowFormatDate();
    this.getList()
  },
  beforeDestroy() {
    uData.clearIntervalNowFormatDate();
  },
  methods: {
	getList: function(){
		let self = this;
		http.get('?m=learnSkills&c=index').then(function(res){
			self.skillsList = res.data.skillList;
		});
	},
    goPath(path){
		this.$router.push({path:path})
    },
    openModal(val, idx) {
		this.qrcode = val.qrcode
		this.title = val.title
		this.jobItem = idx
		this.showqrcode = true
    },
    getMenu() {
      this.isShow = !this.isShow
    },
    goHome(){
      this.$router.push({path:'/home'})
    },
    goback(){
      this.$router.push({path:'/home'})
    },
  }
};
</script>

<style lang="less" scoped>
.header {
  margin-top: 3.4rem;
  border-bottom: 1px solid #fff;
}

.container {
  padding: 0 2.4rem;

  .skillsList {
    width: 100%;
    height: 19.2rem;
    margin-top: 0.625rem;
    overflow-y: auto;
    padding: 0.25rem;

    li {
      border-radius: 0.125rem;
      background-color: #fff;
      margin-bottom: 0.35rem;
      width: 18.1%;
      display: inline-block;
      margin-right: 1rem;
      vertical-align: top;
      &:nth-child(5n){
        margin-right: 0;
      }
    }

    .active {
      box-shadow: 0 0px 2px 2px rgba(255, 255, 255, 0.6);
    }

    .userInfo {
      img {
        width: 100%;
        height: 4.95rem;
      }
      .text {
        padding: 0.45rem;
      }
      .jobname {
        font-size: 0.675rem;
        color: #4c5dfc;
        font-family: "SourceHanSansSC";
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
      }
      .addre {
        font-size: 0.35rem;
        color: #828282;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
		height: 1rem;
      }
    }
  }

  .btn {
    .home {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      left: 2.4rem;
      bottom: 1.3rem;
      cursor: pointer;

      .homeicon {
        background: url("../assets/images/jobImg/hombig.png") no-repeat;
        background-size: 100% 100%;
        width: 1.125rem;
        height: 0.975rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.875rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .menu {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      left: 7.5rem;
      bottom: 1.3rem;
      cursor: pointer;

      .menuicon {
        background: url("../assets/images/jobImg/menuicon.png") no-repeat;
        background-size: 100% 100%;
        width: 0.975rem;
        height: 0.975rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.575rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .back {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      right: 2.4rem;
      bottom: 1.3rem;
      cursor: pointer;

      .backicon {
        background: url("../assets/images/jobImg/back.png") no-repeat;
        background-size: 100% 100%;
        width: 1.125rem;
        height: 1.025rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.875rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .menuBox {
      position: absolute;
      left: 12rem;
      bottom: 1.3rem;
      background: url("../assets/images/jobImg/menubg.png") no-repeat;
      background-size: 100% 100%;
      width: 25.75rem;
      height: 3.425rem;
      padding: 0.55rem 1.05rem 0.55rem 1.5rem;
      li {
        width: 2.05rem;
        height: 2.05rem;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.95rem;
        text-align: center;
        padding-top: 0.35rem;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        p {
          font-size: 0.3rem;
          color: #fff;
          margin-top: 0.4rem;
        }
        i {
          display: block;
          margin: 0 auto;
        }
        .menuicon0 {
          background: url("../assets/images/menuImg/menuicon1.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.6rem;
        }
        .menuicon1 {
          background: url("../assets/images/menuImg/menuicon2.png") no-repeat;
          background-size: 100% 100%;
          width: 0.6rem;
          height: 0.625rem;
        }
        .menuicon2 {
          background: url("../assets/images/menuImg/menuicon3.png") no-repeat;
          background-size: 100% 100%;
          width: 0.65rem;
          height: 0.65rem;
        }
        .menuicon3 {
          background: url("../assets/images/menuImg/menuicon4.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.65rem;
        }
        .menuicon4 {
          background: url("../assets/images/menuImg/menuicon5.png") no-repeat;
          background-size: 100% 100%;
          width: 0.6rem;
          height: 0.675rem;
        }
        .menuicon5 {
          background: url("../assets/images/menuImg/menuicon6.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.675rem;
        }
        .menuicon6 {
          background: url("../assets/images/menuImg/menuicon7.png") no-repeat;
          background-size: 100% 100%;
          width: 0.8rem;
          height: 0.7rem;
        }
        .menuicon7 {
          background: url("../assets/images/menuImg/menuicon8.png") no-repeat;
          background-size: 100% 100%;
          width: 0.7rem;
          height: 0.7rem;
        }
      }
      .menu0 {
        background: url("../assets/images/menuImg/menu1.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu1 {
        background: url("../assets/images/menuImg/menu2.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu2 {
        background: url("../assets/images/menuImg/menu3.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu3 {
        background: url("../assets/images/menuImg/menu4.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu4 {
        background: url("../assets/images/menuImg/menu5.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu5 {
        background: url("../assets/images/menuImg/menu6.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu6 {
        background: url("../assets/images/menuImg/menu7.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu7 {
        background: url("../assets/images/menuImg/menu8.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
/deep/ .ivu-modal {
  background: url(../assets/images/jobImg/zgzbg.png) no-repeat;
  background-size: 100% 100%;
  width: 13.8rem;
  height: 15rem;
  margin-top: 4.25rem;
}
/deep/.ivu-modal-content {
  background: rgba(255, 255, 255, 0);
  box-shadow: none;
}
.modalCon {
  text-align: center;
  padding-top: 1.675rem;
  .jobname {
    font-size: 1.05rem;
    color: #fff;
    font-family: "SourceHanSansSC";
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
  }
  img {
    width: 7.95rem;
    height: 7.95rem;
    margin-top: 1.55rem;
  }
  .box {
    background: url(../assets/images/jobImg/xqbox.png) no-repeat;
    background-size: 100% 100%;
    width: 10.9rem;
    height: 1.325rem;
    font-size: 0.85rem;
    font-weight: bold;
    color: #fff;
    margin: 0 auto;
  }
}
</style>