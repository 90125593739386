<template>
  <div class="frame">
    <div class="title">智慧就业创业综合服务平台<em>(政策详情)</em></div>
    <div class="time">
      <p id="time"></p>
      <span id="date"></span>
      <span id="week" style="margin-left: 0.3rem"></span>
    </div>
    <div class="header"></div>
    <div class="container">
      <div class="policyDetail">
        <div class="Title">
          <h1>{{info.title}}</h1>
          <div class="qrcode" v-if="info.qrcode">
            <img :src="info.qrcode" alt="">
            <p>扫描二维码手机查看</p>
          </div>
        </div>
        <div class="content" ref="scrollRef">
          <div v-html="info.content"></div>
          <div class="img" v-if="isOpen">
            <img :src="info.qrcode" alt="">
          </div>
        </div>
        <div class="backTop">
          <div v-if="info.qrcode" class="imgicon" @click="getValue"></div>
          <div class="backTopIcon" @click="logScrollTop"></div>
        </div>
      </div>
      <div class="btn">
        <button class="home" @click="goHome">
          <i class="homeicon"></i>
          <span>首页</span>
        </button>
        <button class="menu" @click="getMenu">
          <i class="menuicon"></i>
          <span>菜单</span>
        </button>
        <button class="back" @click="goback">
          <i class="backicon"></i>
          <span>返回</span>
        </button>
        <div class="menuBox" v-if="isShow">
          <ul>
            <li v-for="(item,index) in menuList" :key="index" :class="'menu'+index" @click="goPath(item.path)">
              <i :class="'menuicon'+index"></i>
              <p>{{item.menuName}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../utils/request";
import * as uData from "../utils/data";

export default {
  name: "detail",
  data() {
    return {
      policyList: [],
      poliItem: 0,
      menuList: [
        {
          menuName: "我要找工作",
          path:'/job'
        },
        {
          menuName: "我要招人才",
          path:'/personnel'
        },
        {
          menuName: "普工专区",
          path:'/generalWorkers'
        },
        {
          menuName: "我要找零活",
          path:'/gigwork'
        },
        {
          menuName: "业主招零工",
          path:'/oddjobs'
        },
        {
          menuName: "最新政策",
          path:'/policy'
        },
        {
          menuName: "办事流程",
          path:'/processHand'
        },
        {
          menuName: "我要学技能",
          path:'/learnSkills'
        },
      ],
      isShow: false,
      isOpen:false,
      id: 0,
      info: {},
      query: {},
    }
  },
  mounted() {
    uData.runIntervalNowFormatDate();
    this.query = this.$route.query ? this.$route.query : {};
    let query = this.query;
    this.id = query.id ? query.id : 0;
    this.getIndex();
  },
  beforeDestroy() {
    uData.clearIntervalNowFormatDate();
  },
  methods: {
    goPath(path){
      this.$router.push({path:path})
    },
    onchange(val) {
      this.poliItem = val
    },
    getMenu() {
      this.isShow = !this.isShow
    },
    logScrollTop() {
      this.$refs.scrollRef.scrollTop = 0;
    },
    getValue(){
      this.isOpen = !this.isOpen
    },
    goHome(){
      this.$router.push({path:'/home'})
    },
    goback(){
      let query = this.query;
      let tail = "";
      if(query.navItem){
        tail += ("?navItem=" + query.navItem);
      }
      this.$router.push({path:'/policy' + tail})
    },
    getIndex() {
      let self = this;
      let params = {id: self.id};
      http.get('?m=fuwuDetail&c=index', {params: params}).then(function (res) {
        if (!res.data.error) {
          self.info = res.data.info;
        } else {
          self.info = {};
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  margin-top: 3.4rem;
  border-bottom: 1px solid #fff;
}

.container {
  padding: 0 2.4rem;

  .policyDetail {
    margin-top: 0.9rem;
    overflow-y: auto;
    padding: 0.875rem 1.2rem;
    background-color: #4c5dfc;
    .Title {
      border-bottom: 1px solid #fff;
      text-align: center;
      position: relative;
      padding-bottom: 1.9rem;
      h1 {
        font-size: 1.15rem;
        color: #fff;
        font-family: "SourceHanSansSC";
        width: calc(100% - 4rem);
      }
      .qrcode {
        position: absolute;
        top: 0.25rem;
        right: 0.8rem;
        img {
          width: 2.4rem;
          height: 2.4rem;
        }

        p {
          font-size: 0.35rem;
          color: #0cff00;
        }
      }
    }
    .content {
      height: 12.5rem;
      overflow-y: auto;
      margin-top: 0.65rem;
      font-size: 0.5rem;
      color: #fff;
      position: relative;
      img {
        position: fixed;
        bottom: 3rem;
        right: 3.6rem;
        /*white-space: 5rem;*/
        width: 148px;
      }
    }

    .backTopIcon {
      background: url("../assets/images/menuImg/箭头.png")no-repeat;
      background-size: 100% 100%;
      width: 1.725rem;
      height: 1.775rem;
      position: absolute;
      right: 0.5rem;
      bottom: 4.1rem;
    }
    .imgicon {
      background: url("../assets/images/menuImg/sao.png")no-repeat;
      background-size: 100% 100%;
      width: 1.725rem;
      height: 1.775rem;
      position: absolute;
      right: 0.5rem;
      bottom: 6.45rem;
    }
  }

  .btn {
    .home {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      left: 2.4rem;
      bottom: 1.3rem;
      cursor: pointer;

      .homeicon {
        background: url("../assets/images/jobImg/hombig.png") no-repeat;
        background-size: 100% 100%;
        width: 1.125rem;
        height: 0.975rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.875rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .menu {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      left: 7.5rem;
      bottom: 1.3rem;
      cursor: pointer;

      .menuicon {
        background: url("../assets/images/jobImg/menuicon.png") no-repeat;
        background-size: 100% 100%;
        width: 0.975rem;
        height: 0.975rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.575rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .back {
      background-image: url("../assets/images/jobImg/btnbg.png");
      width: 4.575rem;
      height: 1.35rem;
      border-radius: 0.125rem;
      text-align: center;
      font-size: 0.725rem;
      color: #fff;
      position: absolute;
      right: 2.4rem;
      bottom: 1.3rem;
      cursor: pointer;

      .backicon {
        background: url("../assets/images/jobImg/back.png") no-repeat;
        background-size: 100% 100%;
        width: 1.125rem;
        height: 1.025rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.875rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .menuBox {
      position: absolute;
      left: 12rem;
      bottom: 1.3rem;
      background: url("../assets/images/jobImg/menubg.png") no-repeat;
      background-size: 100% 100%;
      width: 25.75rem;
      height: 3.425rem;
      padding: 0.55rem 1.05rem 0.55rem 1.5rem;
      li {
        width: 2.05rem;
        height: 2.05rem;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.95rem;
        text-align: center;
        padding-top: 0.35rem;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        p {
          font-size: 0.3rem;
          color: #fff;
          margin-top: 0.4rem;
        }
        i {
          display: block;
          margin: 0 auto;
        }
        .menuicon0 {
          background: url("../assets/images/menuImg/menuicon1.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.6rem;
        }
        .menuicon1 {
          background: url("../assets/images/menuImg/menuicon2.png") no-repeat;
          background-size: 100% 100%;
          width: 0.6rem;
          height: 0.625rem;
        }
        .menuicon2 {
          background: url("../assets/images/menuImg/menuicon3.png") no-repeat;
          background-size: 100% 100%;
          width: 0.65rem;
          height: 0.65rem;
        }
        .menuicon3 {
          background: url("../assets/images/menuImg/menuicon4.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.65rem;
        }
        .menuicon4 {
          background: url("../assets/images/menuImg/menuicon5.png") no-repeat;
          background-size: 100% 100%;
          width: 0.6rem;
          height: 0.675rem;
        }
        .menuicon5 {
          background: url("../assets/images/menuImg/menuicon6.png") no-repeat;
          background-size: 100% 100%;
          width: 0.625rem;
          height: 0.675rem;
        }
        .menuicon6 {
          background: url("../assets/images/menuImg/menuicon7.png") no-repeat;
          background-size: 100% 100%;
          width: 0.8rem;
          height: 0.7rem;
        }
        .menuicon7 {
          background: url("../assets/images/menuImg/menuicon8.png") no-repeat;
          background-size: 100% 100%;
          width: 0.7rem;
          height: 0.7rem;
        }
      }
      .menu0 {
        background: url("../assets/images/menuImg/menu1.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu1 {
        background: url("../assets/images/menuImg/menu2.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu2 {
        background: url("../assets/images/menuImg/menu3.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu3 {
        background: url("../assets/images/menuImg/menu4.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu4 {
        background: url("../assets/images/menuImg/menu5.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu5 {
        background: url("../assets/images/menuImg/menu6.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu6 {
        background: url("../assets/images/menuImg/menu7.png") no-repeat;
        background-size: 100% 100%;
      }
      .menu7 {
        background: url("../assets/images/menuImg/menu8.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>